import { Component, OnInit, ViewRef } from '@angular/core';
import { WebapiService } from '../webapi.service';
import * as moment from 'moment';
import { keys } from '@amcharts/amcharts4/.internal/core/utils/Object';
import { Router } from '@angular/router';
import { Ocean3b81detailsComponent } from '../ocean3b81details/ocean3b81details.component';
import { ModalController } from '@ionic/angular';
import { ShipercobdataComponent } from '../shipercobdata/shipercobdata.component';
import { OceanShipperDataComponent } from '../ocean-shipper-data/ocean-shipper-data.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { move } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { DemandsensingconfigComponent } from '../demandsensingconfig/demandsensingconfig.component';
import * as XLSX from 'xlsx';
import { CdkObserveContent } from '@angular/cdk/observers';
import { RemarksComponent } from '../remarks/remarks.component';
import { TextAst } from '@angular/compiler';


@Component({
  selector: 'app-demandsensingnew',
  templateUrl: './demandsensingnew.component.html',
  styleUrls: ['./demandsensingnew.component.scss'],
})

export class DemandsensingnewComponent implements OnInit {

  configupdateflag = false;
  selectedtab=0;
  dropdownSettings:IDropdownSettings;
 
  partner_page=0;
  calendar_type='Fiscal';
  page_flag='network';
  year='FY_year'
  quarter='cisco_FY_quarter'
  monht_num='cisco_FY_month_num'

  totalbypartner:any=[]
  lanenotifyconfig:any={
    bsalane:[],
    lane:[],
    emailpref:{
       
      caltype:"Fiscal",
      received:true,
      moved:true
   
    }
  };
  week_key='FYQW'
  // warning threshold (%)
  warning_threshold_perc=70
  overallocation_threshold_perc=90

  time=""
  threshold_config={
    warning_threshold_perc:70,
    overallocation_threshold_perc:90,
    volume:1000
  }
  partner_color=['#C6AC9C','#D1BAAC','#E1CFC4','#ECDFD7',]
  partner_color_serv=['#DC7633','#E59866','#F6DDCC'];
  selectedItems:any;
  currentweekflag=false;
  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}

  filterselection ={
    fiscal_year:"",
    fiscal_quarter:"",
    fiscal_month:"",
    fiscal_week:"",
    theater_origin:"",
    theater_destination:"",
    region_origin:"",
    region_destination:"",
    airport_origin:"",
    airport_destination:"",
    monthweeklist:[],
    selectedlane:"HKG-AMS",
    date:[]


  }
  month=['Jul','Aug','Sept','Oct','Nov','Dec','Jan','Feb','Mar',"Apr",'May','June']
  fiscal_list:any=[];
  lane_region:any=[];
  data_result={output_json:[],df_all:[],df_all_cob:[]}
  partner_allocation:any=[];
  receivedFlag=false;
  Penaltyflag=false;
  resultdata = {
    partner_date:[],
    partner:[],
    date:[],
    total:{"df_total_recepit": 120184.5, "df_total_booked": 120184.5, "df_total_cob": 120184.5},
    week_snap:[],
    partner_config:[],
    alldate:[],
    sl_date:[],
    sl_partner_Date:[]

  }
  today:any;
  today_check:any;
  startofweek:any;
  endofweek:any;

  currentweek;
  master_list:any=[]
  role=""
  

  constructor(public service:WebapiService,public router:Router,public modalController:ModalController) {

    // this.selectedweak = this.activatedRoute.snapshot.paramMap.get('weak').replace(/-/gi,'/');

    // this.selectedlane = this.activatedRoute.snapshot.paramMap.get('lane');
      this.role=localStorage.getItem('role');
      
      ////console.log(this.role)
     var config=localStorage.getItem("thresholdconfig");

     if(config)
     {
      this.threshold_config.warning_threshold_perc=this.warning_threshold_perc=JSON.parse(config).warning_threshold_perc;
      this.threshold_config.overallocation_threshold_perc=this.overallocation_threshold_perc=JSON.parse(config).overallocation_threshold_perc;

     }

      this.response_message="Please select your criteria to view dashboard."

      // var lanes= localStorage.getItem('lanes');
      // if(lanes)
      //   this.selectedItems=JSON.parse(lanes);
      // else
      //   this.selectedItems=[{Lane:'HKG-AMS'},{Lane:'HKG-ELP'},{Lane:'HKG-DFW'},{Lane:'HKG-NRT'},{Lane:'HKG-PEN'}]

       var id=localStorage.getItem('user')
    this.service.postData({iid:id}, 'get_roles').then((result) => {

      //console.log(result);
      var temp: any = result;
      var resultr = temp
      localStorage.setItem("emailpref",temp.record[0].emailpref);

          this.getFavlist();
          this.check_current_week();
          // this.getconfig();
        
          this.get_demand_sensing_lane();

       //console.log(localStorage.getItem("emailpref"));
      this.lanenotifyconfig=JSON.parse(localStorage.getItem("emailpref"));
    });
      
   }
   addtonotify(lane){

    if(this.bsaflag)
      {
        // this.lanenotifyconfig.bsalane.push(lane);
        var i=this.check_notify(lane);
        //console.log(i)
        if(i<0)
         this.lanenotifyconfig.bsalane.push(lane.Lane+"&"+lane.Borrow+"~"+this.selected_partnr);
        else
         this.lanenotifyconfig.bsalane.splice(i,1)
      }
      else{
        var i=this.check_notify(lane);
        if(i<0)
         this.lanenotifyconfig.lane.push(lane.Lane);
        else
         this.lanenotifyconfig.lane.splice(i,1)
      }

       this.configupdateflag=true;
       //console.log(this.lanenotifyconfig);
   }

    updateconfig(){
      var k={
        id:localStorage.getItem('user'),
        emailpref:JSON.stringify(this.lanenotifyconfig),
  
      } 
  
    this.service.postData(k, 'update_emailpref').then((result) => {
      var temp: any = result;
      
      //console.log(temp);
      this.configupdateflag=false;
      
  
  }).catch(e => {
  
    alert("Somthing goes wrong!")
       
  });
  
    }
   //generate_weeks_by_month
  

   async  openFiltermodel(){
      
          const modal = await this.modalController.create({
            component: DemandsensingconfigComponent,
           componentProps: {
                lane_region: this.lane_region,
                lane:this.lanelist,
                filers:this.filers,
                emailpref:this.lanenotifyconfig
              }
          });
          
          modal.onDidDismiss().then(data => {
            ////console.log('dismissed', data);
              this.threshold_config = JSON.parse(localStorage.getItem("thresholdconfig"));
              //console.log('dismissed', this.threshold_config);
             this.warning_threshold_perc=this.threshold_config.warning_threshold_perc;
             this.overallocation_threshold_perc=this.threshold_config.overallocation_threshold_perc;
             this.response_flag=false
             this.response_message="Please wait..we are prepraing your dashboard"
             this.get_demand_sensing_network();

        });
          
          return await modal.present();
        
        }
   
   resetwarning(){

    this.threshold_config.warning_threshold_perc=70
    localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));

   }
   resetoverallocation(){
    this.threshold_config.overallocation_threshold_perc=90;
    localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));
   }
   saveconfig(){
      this.warning_threshold_perc=this.threshold_config.warning_threshold_perc;
      this.overallocation_threshold_perc=this.threshold_config.overallocation_threshold_perc;
      localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));
   }
  getdistinctweek(fiscal_year,fiscal_quarter,fiscal_month){

    this.filterselection.monthweeklist=[]
    var year=this.year;
    var quarter=this.quarter;
    var monht_num=this.monht_num;
    var week=this.week_key;
  
    var list=[]
    list = this.fiscal_list.filter(function(item:any){return item[year]==fiscal_year && item[quarter]==fiscal_quarter&& item[monht_num]==fiscal_month} ) 


    this.filers.fiscal_week=list
    .map((item) => item[week])
    .filter(
        (value, index, current_value) => current_value.indexOf(value) == index
    );

   
    //  for(var i=0;i<list.length;i++){
     
    //   this.filterselection.monthweeklist.push(list[0].Date+"-"+list[6].Date)


    //  }
    // ////console.log(fiscal_month)
    this.filers.fiscal_week.forEach(element => {
      //console.log(this.fiscal_list)
    //console.log(element,fiscal_year)

      list = this.fiscal_list.filter(function(item:any){return item[week]==element && item[year]==fiscal_year} ) 

    console.log(element,fiscal_year)
      console.log(list)
      if(this.calendar_type=='Fiscal')
        {
          this.filterselection.monthweeklist.push(list[0].Date+"-"+list[6].Date)
     
             }
        else
      {
        const date = moment(list[0].Date, 'MM/DD/YYYY')

        ////console.log(moment(list[0].Date, 'MM/DD/YYYY').weekday());
         if(moment(list[0].Date, 'MM/DD/YYYY').weekday()==1)
          this.filterselection.monthweeklist.push(list[0].Date+"-"+date.add(6, 'days').format('M/D/YYYY'))

      }


    });
    ////console.log(this.filers.fiscal_week);
    ////console.log(this.filterselection.monthweeklist);

    
    this.filterselection.fiscal_week=this.filers.fiscal_week[this.filers.fiscal_week.length-1]
    this.selectedweekno=this.filers.fiscal_week.length-1;
    //  var counter= list.length/7;

     
  
    //  var lastindex;
    //  for(var i=0;i<counter;i++)
    //  {
    //     var sindex=i*7
    //  }

    //  if(list.length%7>0)
    //  {
    //     this.filterselection.monthweeklist.push(list[sindex].Date+"-"+list[sindex+6].Date)
    //  }

    // this.filterselection.monthweeklist.push(list[0].Date+"-"+list[6].Date)
    // this.filterselection.monthweeklist.push(list[7].Date+"-"+list[13].Date)
    // this.filterselection.monthweeklist.push(list[14].Date+"-"+list[20].Date)
    // this.filterselection.monthweeklist.push(list[21].Date+"-"+list[27].Date)

    
    // ////console.log(this.filterselection.monthweeklist);
  

        
      // //////////console.log()
        this.response_flag=false
        this.response_message="Please wait..we are prepraing your dashboard"
        this.vflag='volume'
        this.getremarks();
        this.getconfig();
        this.get_demand_sensing_network();


  }

  remarklist:any=[];

  getremarks(){
    this.service.postData({lane:this.filterselection.selectedlane,week:this.filterselection.fiscal_week},"get_remark_demand_filter_count").then(res=>{

      var result:any=res;
      this.remarklist=result.data;
      
      //console.log(this.remarklist);

    });
  }
  getalertcount(lane,week){

    var cnt= this.remarklist.filter(function(item:any){return item.week==week && item.lane==lane} ) 
    if(cnt.length>0)
      return cnt[0].cnt;
    else
     return 0;
  }

  getdates(fw){
      var week=this.week_key;
      var year=this.year;
      this.filterselection.fiscal_week=fw;

       var ctx=this
      this.filterselection.date = this.fiscal_list.filter(function(item:any){return item[week]===fw && item[year] ==  ctx.filterselection.fiscal_year} ) 
      ////console.log(this.filterselection.date);

      ////////console.log(this.filterselection.date);
//      this.getdata_magic()
      // ////console.(list)
  }

  selectedweekno=0;
  
  lanedetails_bsa:any;
  lanedata:any;
  allocationratestable:any=[]
  showdetails(lane,details){
    this.page_flag='lane';
    this.lanedata=details;
    this.filterselection.selectedlane=lane;
    //console.log(this.lanedata);
    this.drawforecast();
    //this.get_data_lane_for_week(this.selectedweekno,lane)
     var weekno=this.selectedweekno;
    var res:any=this.master_list[weekno].data;
      var weekdatalanelist=JSON.parse(res);
      var lanedata = weekdatalanelist
      .filter(function(item:any){return item.Lane===lane}) 
   

     this.getdates(this.filterselection.fiscal_week);
      this.data_result.output_json=lanedata;

      // this.getdistinctpartner_network(weekno);
      console.log(this.data_result.output_json);   
      


      var df_all=JSON.parse(this.master_list[weekno].df_all);
      var df_all_cob=JSON.parse(this.master_list[weekno].df_all_cob);

          
      //console.log(this.data_result.output_json)
      this.data_result.df_all=df_all.filter(function(item:any){return item.Lane===lane}) 

      var k=0
      this.data_result.df_all.forEach(element => {

        if(this.data_result.df_all[k]['Service Level']==null)
          this.data_result.df_all[k]['Service Level']='STD'
        k=k+1;
      });
      k=0
      this.data_result.df_all_cob=df_all_cob.filter(function(item:any){return item.Lane===lane}) 

      this.data_result.df_all_cob.forEach(element => {

        if(this.data_result.df_all_cob[k]['Service Level']==null)
          this.data_result.df_all_cob[k]['Service Level']='STD'
        k=k+1;
      });
           
      // this.data_result.df_all=JSON.parse(res.df_all);
      // this.data_result.df_all_cob=JSON.parse(res.df_all_cob);

            //////////console.log(this.data_result.output_json);
            this.lanedetails_bsa=this.get_daselecteddata();
 

            this.draw_summary_charts();
            this.draw_total_charts();

            this.getdistinctdates();
            this.getdistinctpartner();
            this.getdistinctservicelevel();
            this.getDailybacklog()
            this. generatelistforgrap()
            this.demand_for_trend();
            var i=0;  
            this.allocationratestable=[]
            this.allocation_partnerdist.dataTable.forEach(element => {
      
              if(i>0){
              this.allocationratestable.push({'PARTNER':element[0],'base':element[1],'flex':element[2],'min':element[4],'baserate':this.getallocation_spend_details_baserate(lane,element[0]),'flexrate':this.getallocation_spend_details_flexrate(lane,element[0]),'overrate':this.getallocation_spend_details_overrate(lane,element[0])})
              }
              // this.data_result.output_json[i]['baserate']= this.getallocation_spend_details_baserate(lane,element.PARTNER);
              // this.data_result.output_json[i]['flexrate']= this.getallocation_spend_details_flexrate(lane,element.PARTNER);
              // this.data_result.output_json[i]['overrate']= this.getallocation_spend_details_overrate(lane,element.PARTNER);

              i=i+1;
            }); 
            console.log(this.allocationratestable);


    if(this.vflag=='spend')
    {
      this.setpenalty();
    }else
    {
      this.setsumflag(false);
    }
  }
  no_element=9
  getarray(){
    this.no_element=9

    if(this.selected_partnr!='All')
      this.no_element=15
   var n= ((this.master_partner_list.length/this.no_element))

   n = parseInt((n+"").split('.')[0])
   if(this.master_partner_list.length%this.no_element>0)
     n=n+1;
    // ////console.log(n)
    return Array(n).fill('a');
  }
  change_partner(weekno,lane){

    this.summary_page=0;

    this.selectedItems=""
    this.get_data_lane_for_week(weekno,lane)

  }
  change_week(weekno,lane){
    this.summary_page=0;

    this.selectedItems=""
    // this.selected_partnr="All"
    this.getdistinctpartner_network(weekno);

    console.log(this.network_partners.indexOf(this.selected_partnr));
    if(this.network_partners.indexOf(this.selected_partnr)<0)
      this.selected_partnr="All"

    this.get_data_lane_for_week(weekno,lane)



  }

  set_bsaflag(){
    this.bsaflag=!this.bsaflag;
    if(this.bsaflag)
      {
        if(this.selected_partnr=='All')
        this.selected_partnr=this.network_partners[0];
        this.vflag='volume';
        this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane)


      }
      else{
        this.selected_partnr='All'
        this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane)
        
      }
  }

  clear_lane(){
    this.filers.fiscal_airportcode_destination=[];
    this.filers.fiscal_airportcode_origin=[];
    this.filterselection.airport_destination="";
    this.filterselection.airport_origin="";
    this.filterselection.theater_origin=""
    this.filterselection.theater_destination=""

    this.filterselection.region_origin=""
    this.filterselection.region_destination=""
    this.summary_page=0;

    this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane)


  }
  check_origin_exitst(origin){

     return  this.filers.fiscal_airportcode_origin.indexOf(origin);

  }
  check_destination_exitst(destination){

    return  this.filers.fiscal_airportcode_destination.indexOf(destination);

 }
  global_partner_list:any=[]
  bsaflag=false;

  check_notify(lane){
  
      if(this.bsaflag){
             
        // const pos = this.lanenotifyconfig.bsalane.map(e => e.lane).indexOf(lane);
        var pos= this.lanenotifyconfig.bsalane.indexOf(lane.Lane+"&"+lane.Borrow+"~"+this.selected_partnr);
        var pos1= this.lanenotifyconfig.bsalane.indexOf(lane.Borrow+"&"+lane.Lane+"~"+this.selected_partnr);
        if(pos>-1)
          return pos;
        else if (pos1>-1)
         return pos1
        else
         return -1; 
      }
      else{
        return this.lanenotifyconfig.lane.indexOf(lane.Lane);

      }
  }
  partner_allocation_rate:any=[]
  
  get_data_lane_for_week(weekno,lane)
  {
    this.selectedweekno=weekno;
    this.selectedItems="" 

    this.check_current_week_test(this.filterselection.monthweeklist[this.selectedweekno].split('-')[0])
    this.partner_allocation_rate= JSON.parse(this.partner_spend_config_allowlist[this.selectedweekno])
    //partner_allocation
    this.partner_allocation=JSON.parse(this.master_list[weekno].partner_config);
    //console.log(this.partner_allocation)
    this.lane_allocation = [];
    var list=this.partner_allocation;
      var ctx=this
      list.reduce(function(res, value) {
        if (!res[value.Lane]) {
          res[value.Lane] = { lane: value.Lane ,total_available:0};
          ctx.lane_allocation.push(res[value.Lane])
        }

        var base=0
        if(value.Baseallocation!=null)
         base=value.Baseallocation
        var flex=0
         if(value.Flex!=null)
          flex=value.Flex


        res[value.Lane].total_available += base+flex;

      

        return res;
      }, {});
      ////console.log(this.lane_allocation);
    this.filterselection.fiscal_week=this.filers.fiscal_week[weekno]

    // if(this.page_flag=='network')
    // {
      // var resultg = [];
      // JSON.parse(this.master_list[weekno].data).reduce(function(res, value) {
      //   if (!res[value.Lane]) {
      //     res[value.Lane] = { Lane: value.Lane, total_volume: 0 };
      //     resultg.push(res[value.Lane])
      //   }
      //   res[value.Lane].total_volume += value.total_volume;
      //   return res;
      // }, {});

      var list=JSON.parse(this.master_list[weekno].data);
      var partner=this.selected_partnr;
      //console.log(list)

      if(this.selected_partnr!='All')
       list=list.filter(function(item:any){return item.PARTNER===partner } )

       ////console.log(list)
      var master_partner_list = [];

      var ctx=this
      list.reduce(function(res, value) {
        if (!res[value.Lane]) {
          res[value.Lane] = { Lane: value.Lane, partner_list: [] ,total_volume:0,total_available:0,max:0};
          master_partner_list.push(res[value.Lane])
        }
        res[value.Lane].partner_list.push(value);
        res[value.Lane].total_volume += value.total_volume;

        res[value.Lane].total_available = ctx.getallocation_lane(value.Lane);
        // res[value.Lane].clane=ctx.getallocation_clane(value.Lane);
        // res[value.Lane].total_available =value.Baseallocation+value.Flex

        if(res[value.Lane].total_volume<res[value.Lane].total_available)
          res[value.Lane].max=res[value.Lane].total_available
        else
          res[value.Lane].max=res[value.Lane].total_volume


        return res;
      }, {});


      var group_list=[]
      var single_list=[]

       if(this.selected_partnr!='All'&& this.bsaflag==true)
        {
            
          var master_list_bsa=[]
          master_partner_list.forEach(element => {
            
            var brrow=this.getallocation_lane_partner(element.Lane,this.selected_partnr);
              if(brrow!=null)
                {
                   group_list.push({lane:element.Lane,Borrow:brrow,element:element})
                }
                else{
                  single_list.push({lane:element.Lane,Borrow:"",element:element})
                }

          });

          var impute=[]
          var finallist=[]
          var k=0;
          // group_list.forEach(element => {
          //   // //console.log(impute)

          //   // //console.log(element.lane)

              
          //     if(impute.indexOf(element.lane)>=0)
          //     {

          //          var l= JSON.parse(JSON.stringify(group_list[k].lane));
          //          var br= JSON.parse(JSON.stringify(group_list[k].Borrow));
          //          group_list[k].lane=br;
          //          group_list[k].Borrow=l;
          //          //group_list[k].Borrow=

          //         // //console.log(l,br)

          //     }else{
          //       impute.push(element.Borrow);
          //       // var list= 
          //       // finallist.push(element)
          //     }
          //     k=k+1;

          // });
          
          //new LoadingController;
          group_list.forEach(element => {
            // //console.log(impute)

            //new logic
            // if(impute.indexOf(element.lane)<0)
            //   {
            //     // //console.log(element.lane)
            //       var gstring;
            //       var glist=  group_list[k].Borrow.split(",");
            //       gstring=  group_list[k].lane+","+group_list[k].Borrow
            //       glist.unshift( group_list[k].lane);
            //       group_list.forEach(group => {

            //           if(glist.indexOf(group.lane)>=0)
            //             {
            //               group.Borrow=gstring;
            //               impute.push(group.lane);
            //             }

            //       });

            //   }
               


               

              //old logic
              if(impute.indexOf(element.lane)>=0)
              {

                   var l= JSON.parse(JSON.stringify(group_list[k].lane));
                   var br= JSON.parse(JSON.stringify(group_list[k].Borrow));
                   group_list[k].lane=br;
                   group_list[k].Borrow=l;
                   //group_list[k].Borrow=

                  // //console.log(l,br)

              }else{
                impute.push(element.Borrow);
                // var list= 
                // finallist.push(element)
              }
              k=k+1;

          });

          //console.log(group_list)
        
        var dlane = group_list
        .map((item) => item.Borrow)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
 
         //console.log(dlane)

        dlane.forEach(el => {
          // //console.log(el)

          var list =group_list.filter(function(item:any){
            return item.Borrow==el}); 

            // //console.log(list)
            var odl=JSON.parse(JSON.stringify(list[0].element.partner_list[0]));
            // //console.log(list.slice(1,list.length))
            // //console.log(list.slice(0,list.length-1))

            list.slice(1,list.length).forEach(dlo => {
              
              var dl=JSON.parse(JSON.stringify(dlo.element.partner_list[0]))
              odl.Baseallocation=odl.Baseallocation+dl.Baseallocation;
              odl.Flex=odl.Flex+dl.Flex;
              odl.MinAllocaion=odl.MinAllocaion+dl.MinAllocaion;

              odl.backlog_moved=odl.backlog_moved+dl.backlog_moved;
              odl.backlog_received=odl.backlog_received+dl.backlog_received;
              odl.pw_received=odl.pw_received+dl.pw_received;
              odl.pw_received_twcob=odl.pw_received_twcob+dl.pw_received_twcob;
              odl.total_backlog=odl.total_backlog+dl.total_backlog;
              odl.total_received=odl.total_received+dl.total_received;
              odl.total_volume=odl.total_volume+dl.total_volume;
              odl.tw_moved=odl.tw_moved+dl.tw_moved;
              odl.tw_received=odl.tw_received+dl.tw_received;

              odl.tw_received_twcob=odl.tw_received_twcob+dl.tw_received_twcob;
              odl.COB_WEIGHT_CHARGEABLE_cbk=odl.COB_WEIGHT_CHARGEABLE_cbk+dl.COB_WEIGHT_CHARGEABLE_cbk
             //console.log(odl)

            });

            var obj:any= JSON.parse(JSON.stringify(list[0].element));
            //console.log(obj)
            obj.partner_list[0]=JSON.parse(JSON.stringify(odl));
            
            finallist.push({lane:list[0].lane,Borrow:el,element:obj});
        });

         //console.log(finallist);

         single_list.forEach(el => {
           var  element=el.element;
           if((element.partner_list[0].Baseallocation+element.partner_list[0].Flex)>(element.partner_list[0].tw_moved+element.partner_list[0].tw_received))
            master_list_bsa.push({partner_list:element.partner_list,Lane:el.lane,Borrow:"",total_available:element.partner_list[0].Baseallocation+element.partner_list[0].Flex,total_volume:element.partner_list[0].tw_moved+element.partner_list[0].tw_received,max:(element.partner_list[0].Baseallocation+element.partner_list[0].Flex)})
           else
            master_list_bsa.push({partner_list:element.partner_list,Lane:el.lane,Borrow:"",total_available:element.partner_list[0].Baseallocation+element.partner_list[0].Flex,total_volume:element.partner_list[0].tw_moved+element.partner_list[0].tw_received,max:(element.partner_list[0].tw_moved+element.partner_list[0].tw_received)})

         });
         finallist.forEach(el => {
          var  element=el.element;

          if((element.partner_list[0].Baseallocation+element.partner_list[0].Flex)>(element.partner_list[0].tw_moved+element.partner_list[0].tw_received))
           master_list_bsa.push({partner_list:element.partner_list,Lane:el.lane,Borrow:el.Borrow,total_available:element.partner_list[0].Baseallocation+element.partner_list[0].Flex,total_volume:element.partner_list[0].tw_moved+element.partner_list[0].tw_received,max:(element.partner_list[0].Baseallocation+element.partner_list[0].Flex)})
          else
           master_list_bsa.push({partner_list:element.partner_list,Lane:el.lane,Borrow:el.Borrow,total_available:element.partner_list[0].Baseallocation+element.partner_list[0].Flex,total_volume:element.partner_list[0].tw_moved+element.partner_list[0].tw_received,max:(element.partner_list[0].tw_moved+element.partner_list[0].tw_received)})

        });
        this.global_partner_list=master_list_bsa;


        }
        else{
          this.global_partner_list=master_partner_list;

        }

        //console.log(this.global_partner_list);
        
         
        
      var ctx=this;
      ////console.log(this.filers.fiscal_airportcode_destination.length);
      // //console.log(this.filers.fiscal_airportcode_origin);

      
      if(this.filers.fiscal_airportcode_origin.length>0&&this.filers.fiscal_airportcode_destination.length==0)
      {
        this.master_partner_list=this.global_partner_list.filter(function(item:any){
          return ctx.check_origin_exitst(item.Lane.split('-')[0])>-1});     
          //console.log("1")     
      }
      else if(this.filers.fiscal_airportcode_origin.length==0&&this.filers.fiscal_airportcode_destination.length>0)
      {
        this.master_partner_list=this.global_partner_list.filter(function(item:any){
          return ctx.check_destination_exitst(item.Lane.split('-')[1])>-1});          
          //console.log("2")     

      }
      else if(this.filers.fiscal_airportcode_destination.length>0&&this.filers.fiscal_airportcode_origin.length>0)
      {
      this.master_partner_list=this.global_partner_list.filter(function(item:any){
        return ctx.check_origin_exitst(item.Lane.split('-')[0])>-1 && ctx.check_destination_exitst(item.Lane.split('-')[1])>-1});
        //console.log("3")     

      }
      else{
        this.master_partner_list=this.global_partner_list;
        //console.log("4")     

      }

      // //console.log(this.master_partner_list);

     // var volume=this.threshold_config.volume;

      // this.lanelist=resultg.filter(function(item:any){return item.total_volume>=volume}) ;
      
      this.master_partner_list.sort(this.sortByProperty('max'))
      this.response_flag=true;
      //console.log(this.master_partner_list);


      this.cal_spend();

    // }else{

     if(this.page_flag=='lane')
     {
      var res:any=this.master_list[weekno].data;
      var weekdatalanelist=JSON.parse(res);
      var lanedata = weekdatalanelist
      .filter(function(item:any){return item.Lane===lane}) 
   

     this.getdates(this.filterselection.fiscal_week);
      this.data_result.output_json=lanedata;
      // this.getdistinctpartner_network(weekno);
      console.log(this.data_result.output_json);      


      var df_all=JSON.parse(this.master_list[weekno].df_all);
      var df_all_cob=JSON.parse(this.master_list[weekno].df_all_cob);

          
      //console.log(this.data_result.output_json)
      this.data_result.df_all=df_all.filter(function(item:any){return item.Lane===lane}) 

      var k=0
      this.data_result.df_all.forEach(element => {

        if(this.data_result.df_all[k]['Service Level']==null)
          this.data_result.df_all[k]['Service Level']='STD'
        k=k+1;
      });
      k=0
      this.data_result.df_all_cob=df_all_cob.filter(function(item:any){return item.Lane===lane}) 

      this.data_result.df_all_cob.forEach(element => {

        if(this.data_result.df_all_cob[k]['Service Level']==null)
          this.data_result.df_all_cob[k]['Service Level']='STD'
        k=k+1;
      });
           
      // this.data_result.df_all=JSON.parse(res.df_all);
      // this.data_result.df_all_cob=JSON.parse(res.df_all_cob);

            //////////console.log(this.data_result.output_json);
            this.lanedetails_bsa=this.get_daselecteddata();


            this.draw_summary_charts();
            this.draw_total_charts();

            this.getdistinctdates();
            this.getdistinctpartner();
            this.getdistinctservicelevel();
            this.getDailybacklog()
            this. generatelistforgrap()
            this.demand_for_trend();

    }
            this.response_flag=true;
  //  }

     if(this.vflag=='volume')  
      this.setvolume();
     else
      this.setspend();
  }

  getallocation_spend_details(lane,partner)
  {
   
    var lane= this.partner_spend_config.filter(function(item:any){return item.ORIGIN_BRANCH===lane.split('-')[0] && item.DESTIN_BRANCH===lane.split('-')[1] && item.PARTNER==partner}) 

    if(lane.length>0)
     return lane;
    else {
       return [];
    }

  }

  

  getallocation_spend_details_baserate(lanen,partner)
  {
   
    // //console.log( JSON.parse(this.partner_spend_config_allowlist[this.selectedweekno]));

    var lane= JSON.parse(this.partner_spend_config_allowlist[this.selectedweekno]).filter(function(item:any){return item.Origin===lanen.split('-')[0] && item.Destination===lanen.split('-')[1] && item.PARTNER==partner}) 

    // //console.log(lane);
    if(lane.length>0)
    {
      var rate=lane[0].BaseRate;
      if(rate==null)
         rate=0;


      return rate;

    }
    else {
       return 0;
    }

  }
  getallocation_spend_details_prate(lanen,partner)
  {
   
    // //console.log( JSON.parse(this.partner_spend_config_allowlist[this.selectedweekno]));

    var lane= JSON.parse(this.partner_spend_config_allowlist[this.selectedweekno]).filter(function(item:any){return item.Origin===lanen.split('-')[0] && item.Destination===lanen.split('-')[1] && item.PARTNER==partner}) 

    // //console.log(lane);
    if(lane.length>0)
    {
      var rate=lane[0].OverallocationRate-lane[0].BaseRate;
      if(rate==null)
         rate=0;


      return rate;

    }
    else {
       return 0;
    }

  }
  getallocation_spend_details_overrate(lanen,partner){

    var lane= JSON.parse(this.partner_spend_config_allowlist[this.selectedweekno]).filter(function(item:any){return item.Origin===lanen.split('-')[0] && item.Destination===lanen.split('-')[1] && item.PARTNER==partner}) 

    if(lane.length>0)
    {
      var rate=lane[0].OverallocationRate;
      if(rate==null)
         rate=0;


      return rate;

    }
    else {
       return 0;
    }
  }
  getallocation_spend_details_flexrate(lanen,partner)
  {
   
    var lane= JSON.parse(this.partner_spend_config_allowlist[this.selectedweekno]).filter(function(item:any){return item.Origin===lanen.split('-')[0] && item.Destination===lanen.split('-')[1] && item.PARTNER==partner}) 

    if(lane.length>0)
    {
      var rate=lane[0].FlexRate;
      if(rate==null)
         rate=0;


      return rate;

    }
    else {
       return 0;
    }

  }
  getallocation_spend_details_lane(lanen)
  {
   
    var lane= this.partner_spend_config.filter(function(item:any){return item.ORIGIN_BRANCH===lanen.split('-')[0] && item.DESTIN_BRANCH===lanen.split('-')[1] }) 

    if(lane.length>0)
     return lane;
    else {
       return [];
    }

  }
  

  partner_spend_config:any=[]
  partner_spend_configlist:any=[]

  partner_spend_config_allowlist:any=[]

  vlod=false;

getconfig(){

  this.vlod=true;
  var filter ={'weeklist':this.filterselection.monthweeklist,'fiscal':this.filterselection.fiscal_year}

    this.service.postData(filter, 'get_demand_sensing_penalty').then((result) => {

      var temp: any = result;
      this.partner_spend_configlist=temp.data;

      this.partner_spend_config_allowlist=temp.dataallo;
      console.log(this.partner_spend_config_allowlist)
      this.vlod=false;


      this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane);

      // //console.log(this.partner_spend_config);

    });
  }
  getnonallspend_perc(parner,lane){


     var max=lane;
     var spend=0
     if(parner.length>0)
     {
      spend=parner[0].total_non_alloc_spend;
      if(spend==null)
        spend=0
     }
       

    return (spend/max)*100;
  }

  
cal_spend(){

  //console.log(this.partner_spend_configlist);
  this.partner_spend_config=JSON.parse(this.partner_spend_configlist[this.selectedweekno]);
  var k=0;
  this.master_partner_list.forEach(lane => {


    var j=0;
    var list=this.getallocation_spend_details_lane(lane.Lane)
    var  values = list.map(item => item.total_non_alloc_spend);
    var maxValuetotal_non_alloc_spend=0;
    var maxValueWks_Avg_rate_all=0;
    var maxValueWks_Avg_rate_all_nonall=0;
    var maxalloverallocation=0;
    var maxoverallocation=0;
    var maxalluoverallocation=0;
    var maxuoverallocation=0;
    var maxpenalty=0;
    var allmaxpenalty=0;

    if(list.length>0)
    {
     maxValuetotal_non_alloc_spend = Math.max(...values);
     values = list.map(item => item.Wks_Avg_rate_all);
     maxValueWks_Avg_rate_all = Math.max(...values);
     values = list.map(item => item.Wks_Avg_rate_alloc);
     maxValueWks_Avg_rate_all_nonall = Math.max(...values);

  
     maxalloverallocation = list.map(obj => obj['All Overallocation $ Penalty']).reduce((acc, val) => acc + val, 0);
     maxoverallocation= list.map(obj => obj['Allocated Overallocation $ Penaly']).reduce((acc, val) => acc + val, 0);
     maxalluoverallocation= list.map(obj => obj['All Underallocation $ Penalty']).reduce((acc, val) => acc + val, 0);
     maxuoverallocation= list.map(obj => obj['Allocated Underallocation $ Penaly']).reduce((acc, val) => acc + val, 0);

     //console.log(maxValueWks_Avg_rate_all);

     if(maxalloverallocation>maxalluoverallocation)
     {
       maxpenalty=maxalloverallocation;
     }else{
       maxpenalty=maxalluoverallocation;
     }

     if(maxoverallocation>maxuoverallocation)
     {
       allmaxpenalty=maxoverallocation;
     }else{
       allmaxpenalty=maxuoverallocation;
     }

    }
   
    this.master_partner_list[k]['maxValuetotal_non_alloc_spend']=maxValuetotal_non_alloc_spend;
    this.master_partner_list[k]['maxValueWks_Avg_rate_all']=maxValueWks_Avg_rate_all;
    this.master_partner_list[k]['maxValueWks_Avg_rate_all_nonall']=maxValueWks_Avg_rate_all_nonall;
    this.master_partner_list[k]['maxpenalty']=maxpenalty;
    this.master_partner_list[k]['allmaxpenalty']=allmaxpenalty;

    this.master_partner_list[k]['maxalloverallocation']=maxalloverallocation;
    this.master_partner_list[k]['maxoverallocation']=maxoverallocation;

    this.master_partner_list[k]['maxuoverallocation']=maxuoverallocation;
    this.master_partner_list[k]['maxalluoverallocation']=maxalluoverallocation;

    var overallocation=0,potentialallocation=0,Underallocation=0,overakg=0,poverallkg=0,underkg=0;
    lane.partner_list.forEach(partner => {
      this.master_partner_list[k].partner_list[j]['aflag']=this.check_allocation(partner.PARTNER,lane.Lane);
      this.master_partner_list[k].partner_list[j]['spend']=this.getallocation_spend_details(lane.Lane,partner.PARTNER)

      this.master_partner_list[k].partner_list[j]['overallocation']=this.getoverallocationdetails(partner);
      
      overallocation=overallocation+this.master_partner_list[k].partner_list[j]['overallocation'].getoverallocationdif_a_s
      potentialallocation=potentialallocation+this.master_partner_list[k].partner_list[j]['overallocation'].getoverallocationdif_p_s
      Underallocation=Underallocation+this.master_partner_list[k].partner_list[j]['overallocation'].getminallocationdif_spend
      
      underkg=underkg+this.master_partner_list[k].partner_list[j]['overallocation'].getminallocationdif_v
      overakg=overakg+this.master_partner_list[k].partner_list[j]['overallocation'].getoverallocationdif_a_v
      poverallkg=poverallkg+this.master_partner_list[k].partner_list[j]['overallocation'].getoverallocationdif_p_v

      j=j+1;
    });

    this.master_partner_list[k]['overallocation']=overallocation
    this.master_partner_list[k]['potentialallocation']=potentialallocation
    this.master_partner_list[k]['Underallocation']=Underallocation
    this.master_partner_list[k]['penalty']=Underallocation+overallocation
    this.master_partner_list[k]['ppenalty']=Underallocation+potentialallocation

    this.master_partner_list[k]['overakg']=overakg
    this.master_partner_list[k]['poverallkg']=poverallkg
    this.master_partner_list[k]['underkg']=underkg



    

    k=k+1;
  });
  console.log(this.master_partner_list);

}
  get_daselecteddata(){

    //console.log(this.filterselection.selectedlane);
    var ctx=this;
    var lanedata = this.master_partner_list
    .filter(function(item:any){return item.Lane===ctx.filterselection.selectedlane})

    return lanedata;
  }

  prev()
  {
      this.summary_page=parseInt(this.summary_page+"")-1;
      this.selectedItems=""
  }
  next(){
    this.summary_page=parseInt(this.summary_page+"")+1;
    this.selectedItems=""


  }
  get_data_lane_for_week_fornetwork(weekno,lane)
  {

    this.filterselection.fiscal_week=this.filers.fiscal_week[weekno]
    var res:any=this.master_list[weekno].data;
    var weekdatalanelist=JSON.parse(res);

    // ////console.log(this.selected_partnr)
    var partner=this.selected_partnr;
    if(this.selected_partnr==='All')
    {
      var lanedata = weekdatalanelist
      .filter(function(item:any){return item.Lane===lane}) 
   
      //////////console.log(lanedata);
    
      
     // this.data_result.output_json=lanedata;
      return lanedata;
    }
    else{

      var lanedata = weekdatalanelist
      .filter(function(item:any){return item.Lane===lane && item.PARTNER==partner}) 
   
      //////////console.log(lanedata);
      
     // this.data_result.output_json=lanedata;
      return lanedata;
    }



  }
  

//   sortByProperty_totalvaloume(){  
//     return function(b,a){  
//        if((a.total_backlog+a.total_moved) > b[property])  
//           return 1;  
//        else if(a[property] < b[property])  
//           return -1;  
   
//        return 0;  
//     }  
//  }
  savelanes(){
    localStorage.setItem("lanes",JSON.stringify(this.selectedItems));
    alert("Your list is saved")
  }
  change_caltype(){
    if(this.calendar_type=='Fiscal')
    {   
    this.year='FY_year'
    this.quarter='cisco_FY_quarter',
    this.monht_num='cisco_FY_month_num'
    this.week_key='FYQW'
    }   
     else
     {
      this.year='cal_year',
      this.quarter='cal_quarter',
      this.monht_num='cal_month_num'
      this.week_key='cal_Week_Num'


     }
    // this.get_distinctfiscalyears();
    
    this.filterselection.fiscal_month=""
    this.filterselection.fiscal_quarter=""
    this.filterselection.fiscal_week=""
    this.filterselection.fiscal_year=""
    this.filers.fiscal_week=[]
    this.get_filter_list();
  }
  lane_allocation:any=[]
   get_filter_list(){
    var  filter :any
    ////console.log(this.calendar_type)
    // if(this.calendar_type==='Fiscal')
      filter ={'startdate':this.endofweek.format('MM/DD/YYYY'),nweek:4,weeklist:[]}
    // else
    //   filter ={'startdate':this.endofweek.add(1,'days').format('MM/DD/YYYY'),nweek:4,weeklist:[]}

      
    this.service.postData(filter, 'get_demand_sensing_filters').then((result) => {

      var res:any=result;
      this.fiscal_list=JSON.parse(res.fiscal_json);
      
 
      this.lane_region=JSON.parse(res.lane);
      var list=this.partner_allocation=JSON.parse(res.partner_lane_config_json);
      console.log( this.fiscal_list)

      this.lane_allocation = [];

      var ctx=this
      list.reduce(function(res, value) {
        if (!res[value.lane]) {
          res[value.lane] = { lane: value.lane ,total_available:0};
          ctx.lane_allocation.push(res[value.lane])
        }

        var base=0
        if(value.Baseallocation!=null)
         base=value.Baseallocation
        var flex=0
         if(value.Flex!=null)
          flex=value.Flex


        res[value.lane].total_available += base+flex;

      

        return res;
      }, {});
      ////console.log(this.lane_allocation);
      this.get_distinctfiscalyears();
      this.get_distincttheator();
 
 
     });
   }

   master_partner_list:any=[]
   download_Data:any=[]
   summary_page=0;
   get_demand_sensing_network(){
    var filter ={'weeklist':this.filterselection.monthweeklist,'fiscal':this.filterselection.fiscal_year}
      this.response_message='Please wait, your request is being processed';
      this.service.postData(filter, 'get_demand_sensing_magic_network_new').then((result) => {

        var res:any=result;
        ////console.log(res)
        this.master_list=res.output_json;
        this.download_Data=JSON.parse(res.download_data);
        this.time=res.time;

        ////console.log(this.download_Data)
      

        ////console.log(this.master_list)
        ////////console.log(this.master_list)

        
        
        this.getdates(this.filers.fiscal_week[0]);
        this.getdistinctpartner_network(this.filers.fiscal_week.length-1);
        this.summary_page=0;

        this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane);

      });
   }
   weekdownloadlist:any=[];
   weekdownloadflag=false;
   get_demand_sensing_network_Download(){
    this.weekdownloadflag=true;

    var filter ={'startdate':this.filterselection.monthweeklist[this.selectedweekno].split('-')[0],'enddate':this.filterselection.monthweeklist[this.selectedweekno].split('-')[1]}
      this.response_message='Please wait, your request is being processed';
      this.service.postData(filter, 'get_demand_sensing_magic_download_week').then((result) => {

        var res:any=result;
        ////console.log(res)
        var weekdownloadlist=JSON.parse(res.download_data);

        // this.weekdownloadlist=JSON.parse(res.download_data);
        //console.log(weekdownloadlist)
        var ctx=this;
        if(this.filers.fiscal_airportcode_origin.length>0&&this.filers.fiscal_airportcode_destination.length==0)
          {
            this.weekdownloadlist=weekdownloadlist.filter(function(item:any){
              return ctx.check_origin_exitst(item.ORIGIN_BRANCH)>-1});     
              //console.log("1")     
          }
          else if(this.filers.fiscal_airportcode_origin.length==0&&this.filers.fiscal_airportcode_destination.length>0)
          {
            this.weekdownloadlist=weekdownloadlist.filter(function(item:any){
              return ctx.check_destination_exitst(item.DESTIN_BRANCH)>-1});          
              //console.log("2")     
    
          }
          else if(this.filers.fiscal_airportcode_destination.length>0&&this.filers.fiscal_airportcode_origin.length>0)
          {
          this.weekdownloadlist=weekdownloadlist.filter(function(item:any){
            return ctx.check_origin_exitst(item.ORIGIN_BRANCH)>-1 && ctx.check_destination_exitst(item.DESTIN_BRANCH)>-1});
            //console.log("3")     
    
          }
          else{
            this.weekdownloadlist=weekdownloadlist;
            //console.log("4")     
    
          }
        this.saveAsCSV();
        // this.download_Data=JSON.parse(res.download_data);
        // this.time=res.time;

        // ////console.log(this.download_Data)
      

        // ////console.log(this.master_list)
        // ////////console.log(this.master_list)

        
        
        // this.getdates(this.filers.fiscal_week[0]);
        // this.getdistinctpartner_network(0);
        // this.summary_page=0;

        // this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane);
        this.weekdownloadflag=false;

      });
   }

  

   network_partners:any=[]
   selected_partnr="All";
   getdistinctpartner_network(weekno){
    var res:any=this.master_list[weekno].data;
    var weekdatalanelist=JSON.parse(res);
    this.network_partners =weekdatalanelist
        .map((item) => item.PARTNER)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
        //////////console.log(this.network_partners)
   
        this.network_partners.sort();
   }
   get_alllane_by_partner(partner){
   
    //////////console.log(partner)
    var res:any=this.master_list[this.selectedweekno].data;
    var weekdatalanelist=JSON.parse(res);
    var lanedata = weekdatalanelist
    .filter(function(item:any){return item.PARTNER===partner}) 
 
    //////////console.log(lanedata);
    
   // this.data_result.output_json=lanedata;
    return lanedata;

   }

   todaystr=""
   check_current_week(){
    this.today = moment();
    // this.todaystr=this.today.format("MM/DD/YYYY")
    this.today_check = moment();
    this.startofweek=moment().startOf('week')
    this.endofweek=moment().endOf('week')

    if(this.startofweek.isSame(this.today_check.startOf('week')))
     this.currentweekflag=true;



   //////////console.log(this.currentweekflag,this.startofweek,this.today_check.startOf('week') )

   }
   currentweekflag_w=false;
   check_current_week_test(dateString){

    const date = moment(dateString, );

    // this.today = moment();
    // this.todaystr=this.today.format("MM/DD/YYYY")
    this.today_check = moment();
    // this.startofweek=moment().startOf('week')
    // this.endofweek=moment().endOf('week')
    console.log(dateString,date.format('MM/DD/YYYY'),this.today_check.startOf('week').format('MM/DD/YYYY'),date.isSame(this.today_check.startOf('week')));
      
    if(date.isSame(this.today_check.startOf('week')))
     this.currentweekflag_w=true;
    else
     this.currentweekflag_w=false;



   //////////console.log(this.currentweekflag,this.startofweek,this.today_check.startOf('week') )

   }
   getFavlist(){
      var slist= localStorage.getItem('slist');
      if(slist)
      {
      this.filter_saved_list = JSON.parse(slist);
      }
      else
      {
      this.filter_saved_list=[];
      }
   }
   vflag='volume'
   setspend(){
    this.vflag='spend'
    // if(moment().day()>=3)
    //   this.master_partner_list.sort(this.sortByProperty('maxpenalty'))
    // else
      this.master_partner_list.sort(this.sortByProperty('max'))



   }
   setvolume(){
    this.vflag='volume'
    this.Penaltyflag=false;
    this.master_partner_list.sort(this.sortByProperty('max'))

   }

   sortby="max"
   set_sortby(){


    this.master_partner_list.sort(this.sortByProperty(this.sortby))
   }

   check_for_warning(l){

      return l.pw_received+l.tw_received+l.tw_received_twcob+l.pw_received_twcob>0
   }

   lanelist:any=[]
  check_for_duplicate_orign(lane){

   return this.filers.fiscal_airportcode_origin.indexOf(lane);
  }
  check_for_duplicate_destination(lane){

    return this.filers.fiscal_airportcode_destination.indexOf(lane);
   }
  get_demand_sensing_lane(){
    this.service.postData({}, 'get_demand_sensing_lane_new').then((result) => {
    
      //////////////console.log(result);          
      var temp:any=result; 
      this.lanelist=JSON.parse(temp.data);
      // //////////console.log(this.lanelist)
      // this.lanelist.forEach(element => {

      //   // //////////console.log(this.check_for_duplicate_orign(element.Lane.split('-')[0]))
        
      //   if(this.check_for_duplicate_orign(element.Lane.split('-')[0])<0)
      //      this.filers.fiscal_airportcode_origin.push(element.Lane.split('-')[0])
           
      //   if(this.check_for_duplicate_destination(element.Lane.split('-')[1])<0)
      //         this.filers.fiscal_airportcode_destination.push(element.Lane.split('-')[1])

      // });
      // this.filers.fiscal_airportcode_destination.sort();
      
      // this.service.postData(this.user, 'get_Cm_forcast').then((result) => {

      //   var res:any=result;
      //   this.forcast=res;
      //   //console.log(this.forcast);
      //   this.drawforecast();

      //  });
      this.get_filter_list();




    });
  }

  async remark(){

    var lane=this.filterselection.selectedlane;
    if(this.bsaflag)
    {
      lane=this.filterselection.selectedlane+" & "+ this.lanedetails_bsa[0].Borrow+"/"+this.selected_partnr
    }
    const modal = await this.modalController.create({
      component: RemarksComponent,
     componentProps: {
          lane:lane,
          week:this.filterselection.fiscal_week,
        }
    });
    
   
    
    return await modal.present();

  }

  forcast:any=[];

  forecastgraph:any;
  drawforecast(){
    var LineChartdata = {
      chartType: 'LineChart',
      dataTable: temp,
      options: {
        options: {
  
          title: "Forecast Chart",
          
        },
        hAxis: {  gridlines: {
          color: 'transparent'
        }},
        vAxis :{
          gridlines: {
            color: 'transparent'
          }
        },
        
        pointSize:3,
        // legend: {position: 'top', textStyle: {fontSize: 14}}

        legend: { position: 'top', alignment: 'center' ,textStyle: {fontSize: 12}},
        
        colors: ['#061115','#28A9D6']
        ,
        'chartArea': {'width': '80%'},
      height:340,
      }
  
    }
    // 'AED6F1','#FAD7A0','#7FB3D5',

    var temp=[];
    temp.push(['Week','Air Tonnage in Kg','Ocean Tonnage in Kg'])

    
    var a=0,o=0;
    this.forcast.forEach(element => {

      var air=element[this.filterselection.selectedlane+'_Air'];
      var ocean=element[this.filterselection.selectedlane+'_Sea'];
      var airvalue=undefined
      var oceanvalue=undefined;
      if(air)
      {      
        airvalue=air*1000;
        a++;
      }
      if(ocean)
      {
        oceanvalue=ocean*1000;
        o++;
      }  
      

      if(a>0&&o>0)
       temp.push([element.WeekStart,airvalue,oceanvalue]);
      else if(a>0&&o==0)
      {
        temp[0]=['Week','Air Tonnage in Kg']
        temp.push([element.WeekStart,airvalue]);

      }else if(a==0&&o>0)
        {
          temp[0]=['Week','Ocean Tonnage in Kg']
          temp.push([element.WeekStart,oceanvalue]);
  
        }
      
    });


    LineChartdata.dataTable=temp;

    this.LineChartdataforecast=LineChartdata;
    //console.log(temp);


  }

  LineChartdataforecast:any;
  

   //Shipper and ocean + air 13 week trend
   shipeerdata:any=[]
   week:any=[]

   monthly:any=[]
   list2:any=[]
   flag_3b18='g'
   LineChartdata_13week:any;
   LineChartdata_13week_3b18:any;
   LineChartdata_monthly:any;
   LineChartdata_monthly_3b18:any;
   LineChartdata_13week_receipt:any;
   
  demand_for_trend(){
      var user={

        lane:this.filterselection.selectedlane,
        date:'5/12/2022',
        oring:this.filterselection.selectedlane.split("-")[0],
        destination:this.filterselection.selectedlane.split("-")[1],
        startdate:this.filterselection.date[0].Date,
        enddate:this.filterselection.date[6].Date,
        desti:this.filterselection.selectedlane.split("-")[1],
        week:'6/25/2023',
        partner:''
      }
      this.list2=[]

     this.service.postData(user, 'get_demand_sensing_new').then((result) => {
      var temp:any=result;
      this.list2=temp.data;
      if(this.list2.length>0)
      {
      
     
          this.resultdata.partner_config=JSON.parse(this.list2[0]);
          this.resultdata.partner_config=this.resultdata.partner_config.filter(function(item:any){return item.Partner!=null})

          
          this.resultdata.date=JSON.parse(this.list2[2]);
          this.resultdata.partner_date=JSON.parse(this.list2[1]);
          this.resultdata.sl_date=JSON.parse(this.list2[7]);
          this.resultdata.sl_partner_Date=JSON.parse(this.list2[8]);

          this.shipeerdata=JSON.parse(this.list2[11]);

          this.week=JSON.parse(this.list2[10]);
          this.monthly=JSON.parse(this.list2[15]);

          this.week.reverse()
  
          this.resultdata.total.df_total_booked=0;
          this.resultdata.total.df_total_recepit=0;
          this.resultdata.total.df_total_cob=0
          this.resultdata.date.forEach(element => {
            this.resultdata.total.df_total_booked=this.resultdata.total.df_total_booked+element.COB_WEIGHT_CHARGEABLE_y;
            this.resultdata.total.df_total_cob=this.resultdata.total.df_total_cob+element.COB_WEIGHT_CHARGEABLE;
            this.resultdata.total.df_total_recepit=this.resultdata.total.df_total_recepit+element.COB_WEIGHT_CHARGEABLE_x;
        });
      this.resultdata.partner=JSON.parse(this.list2[3]);
      this.resultdata.week_snap=JSON.parse(this.list2[4]);
      this.resultdata.alldate=JSON.parse(this.list2[6]);
 
      this.drawgraphs();
   
   

  }

      });
   }

  drawgraphs (){


        //13 week graph

        var LineChartdata = {
          chartType: 'ComboChart',
          dataTable: temp,
          options: {
            options: {
      
              title: "Last 13 week's actual demand and Weekly Trends",
              
            },
            hAxis: {  gridlines: {
              color: 'transparent'
            }},
            vAxis :{
              gridlines: {
                color: 'transparent'
              }
            },
            
            pointSize:3,
            // legend: {position: 'top', textStyle: {fontSize: 14}}

            legend: { position: 'top', alignment: 'center' ,textStyle: {fontSize: 12}},
            seriesType: 'bars',
            series: {0: {type: 'line',targetAxisIndex:0,color:'#AED6F1'},1: {type: 'line',targetAxisIndex:0,color:'#17202A'},2: {type: 'line',targetAxisIndex:0,color:'#D5D8DC'}},        
            
            colors: ['#3498DB','#52BE80']
            ,
            'chartArea': {'width': '80%'},
          height:440,
          }
      
        }
        // 'AED6F1','#FAD7A0','#7FB3D5',

        var temp=[];
        temp.push(['Week','Moving average past 2 week','Moving average past 4 week','Moving average past Quarter','Week’s Total Air Kgs',{label: 'Week’s Total Ocean Kgs', type: 'number'}])

        this.week.forEach(element => {

          // var week= (new Date(element.Week).getMonth()+1)+'/'+(new Date(element.Week).getDate()+1)+'/'+new Date(element.Week).getFullYear();
          var utcdate=new Date(element.Week).toLocaleString('en-US', { timeZone: 'UTC' });
        var week=utcdate.split(",")[0]

          var mavg2=0
          var mavg4=0;
          var mavg3=0;
          var ocean_weekly=null
          if(element['Moving Avg 2wks']!=null)
            mavg2=element['Moving Avg 2wks']
          if(element['Moving Avg 4wks']!=null)
            mavg4=element['Moving Avg 4wks']
          if(element['Moving Avg 3mnths']!=null)
            mavg3=element['Moving Avg 3mnths']

          if(element['OCEAN_WEIGHT_CHARGEABLE_y']!=null)
            ocean_weekly=element['OCEAN_WEIGHT_CHARGEABLE_y']

          
            
          temp.push([week,mavg2,mavg4,mavg3,element['Observed Chargeable Weight'],ocean_weekly])
          
        });

        //////////////console.log(temp);
        LineChartdata.dataTable=temp;
        this.LineChartdata_13week=LineChartdata;
        //////////////console.log(this.LineChartdata_13week)

        //3ba8

            //13 week graph

          var  LineChartdatak = {
              chartType: 'ComboChart',
              dataTable: temp,
              options: {
                options: {
          
                  title: "Last 13 week's actual demand and Weekly Trends",
                  
                },
                hAxis: {  gridlines: {
                  color: 'transparent'
                }},
                vAxis :{
                  gridlines: {
                    color: 'transparent'
                  }
                },
                
                pointSize:3,
                legend: { position: 'top', alignment: 'center' },
                seriesType: 'bars',
                targetAxisIndex:0,
                // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'bars',targetAxisIndex:1},2: {type: 'bars',targetAxisIndex:1},3: {type: 'line',targetAxisIndex:0},4: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},5: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"},6: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"green"}},
                series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
                
                colors: ['#F5B7B1','#2C3E50']
                ,
              height:440,
              }
          
            }
        
            var temp=[];
            temp.push(['Week','% Variance','Week’s Total Air Kgs',{role:'annotation'},{label: 'Week’s Total Ocean Kgs', type: 'number'},{label: 'Planned Weight Kg', type: 'number'}])
        
            this.week.forEach(element => {
        
              // var week= (new Date(element.Week).getMonth()+1)+'/'+(new Date(element.Week).getDate()+1)+'/'+new Date(element.Week).getFullYear();
              var utcdate=new Date(element.Week).toLocaleString('en-US', { timeZone: 'UTC' });
              var week=utcdate.split(",")[0]          
              var mavg2=0
              var mavg4=0;
              var mavg3=0;
              var ocean_weekly=null
              var receipt_weekly=null
              var total_weekly=null
              if(element['Variance to 3B18']!=null)
                mavg2=element['Variance to 3B18']*100

              // if(element['Moving Avg 2wks']!=null)
              //   mavg2=element['Moving Avg 2wks']
              if(element['Moving Avg 4wks']!=null)
                mavg4=element['Moving Avg 4wks']
              if(element['Moving Avg 3mnths']!=null)
                mavg3=element['Moving Avg 3mnths']
        
              if(element['OCEAN_WEIGHT_CHARGEABLE_y']!=null)
                ocean_weekly=element['OCEAN_WEIGHT_CHARGEABLE_y']
              
              if(element['Receipt_Weight']!=null)
                receipt_weekly=element['Receipt_Weight']
              if(element['total_weight_kg']!=null)
                total_weekly=element['total_weight_kg']
        
              
                
              temp.push([week,mavg2,element['Observed Chargeable Weight'],element['Observed Chargeable Weight'],ocean_weekly,total_weekly])
      
              
            });
        
            //////////////console.log(temp);
            LineChartdatak.dataTable=temp;
            this.LineChartdata_13week_3b18=LineChartdatak;
            //////////////console.log(this.LineChartdata_13week)

                //total weight kg

            //13 week graph

            var  LineChartdatakt = {
              chartType: 'ComboChart',
              dataTable: temp,
              options: {
                options: {
          
                  title: "Last 13 week's actual demand and Weekly Trends",
                  
                },
                hAxis: {  gridlines: {
                  color: 'transparent'
                }},
                vAxis :{
                  gridlines: {
                    color: 'transparent'
                  }
                },
                
                pointSize:3,
                legend: { position: 'top', alignment: 'center' },
                seriesType: 'bars',
                targetAxisIndex:0,
                // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'bars',targetAxisIndex:1},2: {type: 'bars',targetAxisIndex:1},3: {type: 'line',targetAxisIndex:0},4: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},5: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"},6: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"green"}},
                series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
                
                colors: ['#F5B7B1','#2C3E50']
                ,
              height:440,
              }
          
            }
        
            var temp=[];
            temp.push(['Week','% Variance','Week’s Total Air Kgs',{role:'annotation'},{label: 'Week’s Total Ocean Kgs', type: 'number'},{label: 'Receipts Weight Kg', type: 'number'}])
        
            this.week.forEach(element => {
        
              var utcdate=new Date(element.Week).toLocaleString('en-US', { timeZone: 'UTC' });
              var week=utcdate.split(",")[0]          
              
              var mavg2=0
              var mavg4=0;
              var mavg3=0;
              var ocean_weekly=null
              var receipt_weekly=null
              var total_weekly=null
              if(element['Variance to Receipts']!=null)
                mavg2=element['Variance to Receipts']*100

              // if(element['Moving Avg 2wks']!=null)
              //   mavg2=element['Moving Avg 2wks']
              if(element['Moving Avg 4wks']!=null)
                mavg4=element['Moving Avg 4wks']
              if(element['Moving Avg 3mnths']!=null)
                mavg3=element['Moving Avg 3mnths']
        
              if(element['OCEAN_WEIGHT_CHARGEABLE_y']!=null)
                ocean_weekly=element['OCEAN_WEIGHT_CHARGEABLE_y']
              
              if(element['Receipt_Weight']!=null)
                receipt_weekly=element['Receipt_Weight']
              if(element['total_weight_kg']!=null)
                total_weekly=element['total_weight_kg']
        
              
                
              temp.push([week,mavg2,element['Observed Chargeable Weight'],element['Observed Chargeable Weight'],ocean_weekly,receipt_weekly])
      
              
            });
        

          //////////////console.log(temp);
          LineChartdatakt.dataTable=temp;
          this.LineChartdata_13week_receipt=LineChartdatakt;


          //monthly 3b

          var  LineChartdatam3b = {
            chartType: 'ComboChart',
            dataTable: temp,
            options: {
              options: {
        
                title: "Last 13 week's actual demand and Weekly Trends",
                
              },
              hAxis: {  gridlines: {
                color: 'transparent'
              }},
              vAxis :{
                gridlines: {
                  color: 'transparent'
                }
              },
              
              pointSize:3,
              legend: { position: 'top', alignment: 'center' },
              seriesType: 'bars',
              targetAxisIndex:0,
              // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'bars',targetAxisIndex:1},2: {type: 'bars',targetAxisIndex:1},3: {type: 'line',targetAxisIndex:0},4: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},5: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"},6: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"green"}},
              series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
              
              colors: ['#F5B7B1','#2C3E50']
              ,
            height:440,
            }
        
          }
      
          var temp=[];
          temp.push(['Week','% Variance','Week’s Total Air Kgs',{role:'annotation'},{label: 'Week’s Total Ocean Kgs', type: 'number'},{label: 'Planned Weight Kg', type: 'number'}])
      
          this.monthly.forEach(element => {
      
            // var week= (new Date(element.Week).getMonth()+1)+'/'+(new Date(element.Week).getDate()+1)+'/'+new Date(element.Week).getFullYear();
            var utcdate=new Date(element.Month).toLocaleString('en-US', { timeZone: 'UTC' });
            var week=utcdate.split(",")[0]          
            var mavg2=0
            var mavg4=0;
            var mavg3=0;
            var ocean_weekly=null
            var receipt_weekly=null
            var total_weekly=null
            if(element['Variance to 3B18']!=null)
              mavg2=element['Variance to 3B18']*100

            // if(element['Moving Avg 2wks']!=null)
            //   mavg2=element['Moving Avg 2wks']
    
            // if(element['Moving Avg 4wks']!=null)
            //   mavg4=element['Moving Avg 4wks']
            // if(element['Moving Avg 3mnths']!=null)
            //   mavg3=element['Moving Avg 3mnths']
      
            if(element['OCEAN_WEIGHT_CHARGEABLE']!=null)
              ocean_weekly=element['OCEAN_WEIGHT_CHARGEABLE']
            
            if(element['Receipt_Weight']!=null)
              receipt_weekly=element['Receipt_Weight']
            if(element['total_weight_kg']!=null)
              total_weekly=element['total_weight_kg']
                        
            temp.push([week,mavg2,element['WEIGHT_CHARGEABLE'],element['WEIGHT_CHARGEABLE'],ocean_weekly,total_weekly]);
            
          });
      
          //////////////console.log(temp);
          LineChartdatam3b.dataTable=temp;
          this.LineChartdata_monthly_3b18=LineChartdatam3b;
          //////////////console.log(this.LineChartdata_monthly_3b18);

          //

          var  LineChartdatam = {
            chartType: 'ComboChart',
            dataTable: temp,
            options: {
              options: {
        
                title: "Last 13 week's actual demand and Weekly Trends",
                
              },
              hAxis: {  gridlines: {
                color: 'transparent'
              }},
              vAxis :{
                gridlines: {
                  color: 'transparent'
                }
              },
              
              pointSize:3,
              legend: { position: 'top', alignment: 'center' },
              seriesType: 'bars',
              targetAxisIndex:0,
              // series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'bars',targetAxisIndex:1},2: {type: 'bars',targetAxisIndex:1},3: {type: 'line',targetAxisIndex:0},4: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},5: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"},6: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"green"}},
              series: {0: {type: 'bars',targetAxisIndex:1},1: {type: 'line',targetAxisIndex:0},2: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"blue"},3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"red"}},        
              
              colors: ['#F5B7B1','#2C3E50']
              ,
            height:440,
            }
        
          }
      
          var temp=[];
          temp.push(['Week','% Variance','Week’s Total Air Kgs',{role:'annotation'},{label: 'Week’s Total Ocean Kgs', type: 'number'},{label: 'Receipts Weight Kg', type: 'number'}])
      
          this.monthly.forEach(element => {
      
            var utcdate=new Date(element.Month).toLocaleString('en-US', { timeZone: 'UTC' });
            var week=utcdate.split(",")[0]          
            
            var mavg2=0
            var mavg4=0;
            var mavg3=0;
            var ocean_weekly=null
            var receipt_weekly=null
            var total_weekly=null
            if(element['Variance to Receipts']!=null)
              mavg2=element['Variance to Receipts']*100

            // if(element['Moving Avg 2wks']!=null)
            //   mavg2=element['Moving Avg 2wks']
            // if(element['Moving Avg 4wks']!=null)
            //   mavg4=element['Moving Avg 4wks']
            // if(element['Moving Avg 3mnths']!=null)
            //   mavg3=element['Moving Avg 3mnths']
      
            if(element['OCEAN_WEIGHT_CHARGEABLE']!=null)
              ocean_weekly=element['OCEAN_WEIGHT_CHARGEABLE']
            
            if(element['Receipt_Weight']!=null)
              receipt_weekly=element['Receipt_Weight']
            if(element['total_weight_kg']!=null)
              total_weekly=element['total_weight_kg']
      
            
              
            temp.push([week,mavg2,element['WEIGHT_CHARGEABLE'],element['WEIGHT_CHARGEABLE'],ocean_weekly,receipt_weekly])

            
          });
      

        //////////////console.log(temp);
        LineChartdatam.dataTable=temp;
        this.LineChartdata_monthly=LineChartdatam;
  }


//13 Week trend events
  selectweek(event) {
    if(event.selectedRowValues.length>0)
    {
      if(event.column==1)
      {
        //this.presentModal_3b18_d(this.week[event.row])
      }else 
      {
          if(this.flag_3b18=='g'&& event.column==6){
        
          var k=this.LineChartdata_13week.dataTable[event.row+1][0];

          var padb=this.shipeerdata.filter(function(item:any){
          
            var utcdate=new Date(item.Week).toLocaleString('en-US', { timeZone: 'UTC' });
            var w=utcdate.split(",")[0]
            return w===k
          
          })
          this.service.shipperdata=padb;
         this.presentModal();
        }
       if((this.flag_3b18=='p'||this.flag_3b18=='r')&& event.column==4){
     
        var k=this.LineChartdata_13week.dataTable[event.row+1][0];
 
        var padb=this.shipeerdata.filter(function(item:any){
       
         var utcdate=new Date(item.Week).toLocaleString('en-US', { timeZone: 'UTC' });
         var w=utcdate.split(",")[0]
         return w===k
       
          })
            this.service.shipperdata=padb;
           this.presentModal();
        }  
      }
     }
    
   }

  selectweeklist(event,flag) {
    if(event.selectedRowValues.length>0)
    {
       

     if(event.column==1)
     {

       this.flag_3b18=flag;
      
     }else 
     {
       if(this.flag_3b18=='g'&& event.column==6){
     
       var k=this.LineChartdata_13week.dataTable[event.row+1][0];
       var padb=this.shipeerdata.filter(function(item:any){
      
        var utcdate=new Date(item.Week).toLocaleString('en-US', { timeZone: 'UTC' });
        var w=utcdate.split(",")[0]
        return w===k
      
      })
       this.service.shipperdata=padb;
       this.presentModal();
       }
       if((this.flag_3b18=='p'||this.flag_3b18=='r')&& event.column==4){
     
        var k=this.LineChartdata_13week.dataTable[event.row+1][0];
 
        var padb=this.shipeerdata.filter(function(item:any){
       
         var utcdate=new Date(item.Week).toLocaleString('en-US', { timeZone: 'UTC' });
         var w=utcdate.split(",")[0]
         return w===k
       
       })
        this.service.shipperdata=padb;
          this.presentModal();
        }  
      }
     }
    
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: OceanShipperDataComponent,
      // componentProps: {
      // //  bardata: this.bars
      // }
    });
    return await modal.present();
  }

  async presentModal_3b18() {
    const modal = await this.modalController.create({
      component: Ocean3b81detailsComponent,
      componentProps: {
         weekdata: this.week,
         flag:'w'
      }
    });
    return await modal.present();
  }
  
  async presentModal_3b18_d(w) {
    //////////////console.log(w);

    const modal = await this.modalController.create({
      component: Ocean3b81detailsComponent,
      componentProps: {
         weekdata: this.week,
         flag:'d',
         selectedweek:w
      }
    });
    return await modal.present();
  }

  async presentModal_shipper_d(w) {
    //////////////console.log(w);

    this.user.date=w;
    const modal = await this.modalController.create({
      component: ShipercobdataComponent,
      componentProps: {
        user: this.user,
        flag:'d'
      }
    });
    return await modal.present();
  }


   lane_moved_summary:any;
   alloaction_summary:any;
   allocation_partnerdist:any;
   
  

   backlog_moved=0
   backlog_received=0
   received=0;
   received_moved=0;
   pw_received=0;
   total_backlog=0
   partner_allocation_flag=true;

   total_min=0
   booked=0
   lane_moved_summary_bsa:any;
   alloaction_summary_bsa:any;
   allocation_partnerdist_bsa:any;
  draw_summary_charts(){
    var moved_summary_new = {
      chartType: 'PieChart',
      dataTable: [],
       options: {
        options: {
  
          title: 'Moved Summary',
          
        },
      pieSliceTextStyle: {
            color: 'black'
        },        pieHole:0.4,

        pieStartAngle: 0,

        colors: ['#1E8449','#27AE60','#7DCEA0','#D4EFDF']
        ,
        'chartArea': {'width': '100%', 'height': '90%'},

      height:150,
      }

    }

    
    var temp_sum=[];
    temp_sum.push(["Type","Total"])

    // var backlog_moved=0
    // var backlog_received=0
    // var received=0;
    // var received_moved=0;
    this.backlog_moved=0
    this.backlog_received=0
    this.received=0;
    this.received_moved=0;
    this.booked=0
    this.pw_received=0;
    this.total_min=0;
    this.total_backlog=0;
    this.data_result.output_json.forEach(element => {
      
        this.backlog_moved=this.backlog_moved+element.backlog_moved;
        this.backlog_received=this.backlog_received+element.backlog_received;
        this.received=this.received+element.tw_received;
        this.received_moved=this.received_moved+element.tw_received_twcob;
        this.pw_received=this.pw_received+element.pw_received;
        this.total_min=this.total_min + element.MinAllocaion
        this.total_backlog=this.total_backlog+element.total_backlog
        this.booked=this.booked+element.COB_WEIGHT_CHARGEABLE_cbk;

    });
    
    temp_sum.push(["Backlog moved",this.backlog_moved])
    temp_sum.push(["Received moved",this.received_moved])

    temp_sum.push(["Backlog received",this.backlog_received])
    temp_sum.push(["Received",this.received])


    ////////console.log(temp_sum)
    moved_summary_new.dataTable=temp_sum;
    this.lane_moved_summary=JSON.parse(JSON.stringify(moved_summary_new));
    var temp_sum=[];
    temp_sum.push(["Type","Total"])
    temp_sum.push(["Backlog moved",this.lanedetails_bsa[0].partner_list[0].backlog_moved])
    temp_sum.push(["Received moved",this.lanedetails_bsa[0].partner_list[0].tw_received_twcob])

    temp_sum.push(["Backlog received",this.lanedetails_bsa[0].partner_list[0].backlog_received])
    temp_sum.push(["Received",this.lanedetails_bsa[0].partner_list[0].tw_received])
    moved_summary_new.dataTable=temp_sum;
    this.lane_moved_summary_bsa=JSON.parse(JSON.stringify(moved_summary_new));
    var available_sumarry= {
      chartType: 'PieChart',
      dataTable: [],
       options: {
        options: {
  
          title: 'Available Summary',
          
        },
        pieSliceText:"none",

        pieStartAngle: 0,

        colors:  ['#80DEEA','#D4E6F1','#000']
        ,
        'chartArea': {'width': '100%', 'height': '90%'},

      height:150,
      }
  
    }
    var temp=[]
    var allocation_partnerdist = {
      chartType: 'BarChart',
      dataTable: temp,
       options: {
        
      seriesType: 'bars',
        series: {2: {type: 'line',lineWidth: 0
      }},
        options: {
  
          title: 'Monthly Spend Variance',
          
        },
        'chartArea': {'width': '50%', 'height': '85%'},

        annotations: {
          textStyle: {
          color:'black',
          fontSize:12
          }
        },
        // legend:'none',
        legend: { position: 'top', alignment: 'left' },

        bar: { groupWidth: "50%" },

        vAxis: {  gridlines: {
          color: 'transparent'
        }
      },

      hAxis: {  gridlines: {
        color: 'transparent',

       }        
      },
  
    
        isStacked:"true",
        pointSize: 5,

        // vAxes: {
        //   // Adds titles to each axis.
        //   0: {title: 'Chargable Weeight'},
        // }, 
        colors:  ['#80DEEA','#D4E6F1','#000']
        ,
      height:150,
      }
    }
    var list=this.getpartner_allocation(this.filterselection.selectedlane.split("-")[0],this.filterselection.selectedlane.split("-")[1]);
      var total_base=0;
      var total_flex=0;
      var temp1=[]
      var temp_partner=[]
      temp1.push(["Allocation Type","Total"])
      temp_partner.push(["Partner","Base","Flex",{role:'annotation'},'Min'])

      ////console.log(list)
      if(list.length==0)
        this.partner_allocation_flag=false;
      else
        this.partner_allocation_flag=true;
    list.forEach(element => {
    
       var base=0;
       var flex=0;
       var a=0;
       if(this.getMinallocation(element.Partner).MinAllocaion==null)
       a=0
     else
       a=this.getMinallocation(element.Partner).MinAllocaion;
       if(element.Baseallocation!=null)
         base=element.Baseallocation
       if(element.Flex!=null)
        flex=element.Flex; 

        temp_partner.push([element.PARTNER,base,flex,base+flex,a])
        total_base=total_base+base;
        total_flex=total_flex+flex;

      });
      temp1.push(["Baseallocation",total_base])
      temp1.push(["Flex",total_flex])




      available_sumarry.dataTable=temp1;
      ////console.log(available_sumarry)

      this.alloaction_summary=JSON.parse(JSON.stringify(available_sumarry));
      allocation_partnerdist.dataTable=temp_partner;
      ////console.log(allocation_partnerdist)

      this.allocation_partnerdist=JSON.parse(JSON.stringify(allocation_partnerdist));

      var temp1=[]
      var temp_partner=[]
      var details = this.lanedetails_bsa[0].partner_list[0];
      temp1.push(["Allocation Type","Total"])
      temp_partner.push(["Partner","Base","Flex",{role:'annotation'},'Min'])

      temp1.push(["Baseallocation",details.Baseallocation])
      temp1.push(["Flex",details.Flex])
      
      temp_partner.push([details.PARTNER,details.Baseallocation,details.Flex,details.Baseallocation+details.Flex,details.MinAllocaion])
      available_sumarry.dataTable=temp1;

      this.alloaction_summary_bsa=JSON.parse(JSON.stringify(available_sumarry));
      
      allocation_partnerdist.dataTable=temp_partner;
      ////console.log(allocation_partnerdist)
      allocation_partnerdist.dataTable=temp_partner;
      this.allocation_partnerdist_bsa=JSON.parse(JSON.stringify(allocation_partnerdist));
  }

  
  user={

    lane:'HKG-AMS',
    date:'5/12/2022',
    oring:'HKG',
    destination:'AMS',
    startdate:'6/25/2023',
    enddate:'7/1/2023',
    desti:'AMS',
    week:'6/25/2023',
    partner:''
  }
  
  partner_moved_backlog_received:any;

  summary_moved_backlog_received:any;
  draw_total_charts(){

    var moved_summary = {
      chartType: 'PieChart',
      dataTable: [],
       options: {
        options: {
  
          title: 'Moved Summary',
          
        },
        
        pieSliceTextStyle: {
          color: 'black'
      },        
      pieHole:0.4,

        pieStartAngle: 180,

        colors: ['#2ECC71','#9B59B6','#D5F5E3']
        ,
        'chartArea': {'width': '75%', 'height': '90%'},

      height:150,
      }

    }

    
   

    var partner_summary_moved=[]

  var moved=0
  var backlog=0
  var received=0;
    partner_summary_moved.push(["Partner","Total Moved","Backlog Received","Received",{role:"annotation"}])
    this.data_result.output_json.forEach(element => {
      
      // this.backlog_moved=element.backlog_moved;
      // this.backlog_received=this.backlog_received+element.backlog_received;
      // this.received=this.received+element.tw_received;
      // this.received_moved=this.received_moved+element.tw_received_twcob;

      moved=moved+(element.backlog_moved+element.tw_received_twcob)
      backlog=backlog+element.backlog_received+element.tw_received

      received=received+(element.tw_received+element.tw_received_twcob)


      if(this.check_for_warning(element))
        partner_summary_moved.push([element.PARTNER,(element.backlog_moved+element.tw_received_twcob),(element.backlog_received),(element.tw_received),((element.backlog_moved+element.tw_received_twcob)+(element.backlog_received)+(element.tw_received)).toLocaleString(this.lc,this.opt)])


      
  });

  var temp=[];
  temp.push(["Type","Total"])

  // this.data_result.output_json.forEach(element => {
    
      // moved=this.backlog_moved+this.received_moved;
      // backlog=this.backlog_received;
      // received=this.received;
      
  // });
  temp.push(["Total Moved",moved])
  temp.push(["Backlog Received",this.backlog_received])
  temp.push(["Received",this.received])

  moved_summary.dataTable=temp;
  this.summary_moved_backlog_received=moved_summary;


  var allocation_partnerdist = {
    chartType: 'BarChart',
    dataTable: temp,
     options: {
      
    // seriesType: 'bars',
    //   series: {2: {type: 'line',lineWidth: 0
    // }},
      options: {

        title: 'Monthly Spend Variance',
        
      },
      'chartArea': {'width': '75%', 'height': '85%'},

      annotations: {
        textStyle: {
        color:'black',
        fontSize:12
        }
      },
      // legend:'none',
      legend: { position: 'top', alignment: 'left' },


      vAxis: {  gridlines: {
        color: 'transparent'
      }
    },

    hAxis: {  gridlines: {
      color: 'transparent',

     }        
    },

  
      isStacked:"true",
      pointSize: 5,

      // vAxes: {
      //   // Adds titles to each axis.
      //   0: {title: 'Chargable Weeight'},
      // }, 
      colors: ['#2ECC71','#9B59B6','#D5F5E3']
      ,
    height:150,
    }
  }

  allocation_partnerdist.dataTable=partner_summary_moved;
  this.partner_moved_backlog_received=allocation_partnerdist;


  }

  help(){
    this.router.navigateByUrl("/info")
  }
  alloaction(){
    this.router.navigateByUrl("/dailytraining")

  }
  reset(){
    this.filterselection ={
      fiscal_year:"",
      fiscal_quarter:"",
      fiscal_month:"",
      fiscal_week:"",
      theater_origin:"",
      theater_destination:"",
      region_origin:"",
      region_destination:"",
      airport_origin:"",
      airport_destination:"",
      monthweeklist:[],
selectedlane:"",
      date:[]
  
  
    }
    this.response_message="Please provide your selection criteria and click Show"

    this.filers.fiscal_week=[];
    
    this.lanelist.forEach(element => {

      // //////////console.log(this.check_for_duplicate_orign(element.Lane.split('-')[0]))
      
      if(this.check_for_duplicate_orign(element.Lane.split('-')[0])<0)
         this.filers.fiscal_airportcode_origin.push(element.Lane.split('-')[0])
         
      if(this.check_for_duplicate_destination(element.Lane.split('-')[1])<0)
            this.filers.fiscal_airportcode_destination.push(element.Lane.split('-')[1])

    });
    this.filers.fiscal_airportcode_destination.sort();
    this.response_flag=false;
  }
  getMinallocation(partner)
  {
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    var lane= this.partner_allocation.filter(function(item:any){return item.Partner===partner && item.Origin===origin && item.Destination===destination}) 
    if(lane.length>0)
     return lane[0];
    else {
       return [{"MinAllocaion":0}];
    }
  }
  getallocation(partner)
  {
    ////console.log(partner)
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    ////console.log(this.partner_allocation)

    ////console.log(this.filterselection.selectedlane)

    var lane= this.partner_allocation.filter(function(item:any){return item.Partner===partner && item.Origin===origin && item.Destination===destination}) 
    ////console.log(lane)
    if(lane.length>0)
     return lane[0].Baseallocation+lane[0].Flex;
    else {
       return 0;
    }
  }
  
  getallocation_n(partner)
  {
    ////console.log(partner)
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
   

    var lane= this.partner_allocation.filter(function(item:any){return item.PARTNER===partner && item.Origin===origin && item.Destination===destination}) 
    if(lane.length>0)
     return lane[0].Baseallocation+lane[0].Flex;
    else {
       return 0;
    }
  }

  getallocation_network(partner,lane)
  {
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    var lane= this.partner_allocation.filter(function(item:any){return item.Partner===partner && item.Origin===lane.split('-')[0] && item.Destination===lane.split('-')[1]}) 
    if(lane.length>0)
     return lane[0].Baseallocation+lane[0].Flex;
    else {
       return 0;
    }
  }
  getallocation_lane(lane)
  {
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    var lane= this.lane_allocation.filter(function(item:any){return item.lane===lane}) 
    if(lane.length>0)
     return lane[0].total_available;
    else {
       return 0;
    }
  }
  getallocation_lane_partner(lane,partner)
  {
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    var lane= this.partner_allocation.filter(function(item:any){return item.Lane===lane && item.PARTNER==partner}) 

    if(lane.length>0)
     return lane[0].Borrrow;
    else {
       return null;
    }

  }
  get_threshold_moved(obj){
    // //////////console.log(obj)
    
    if((obj.Baseallocation+obj.Flex)==0)
     return 'n'

    return   ((obj.backlog_moved+obj.tw_received_twcob)/(obj.Baseallocation+obj.Flex))*100;
  
  }
  check_threshold_moved(obj){
    // //////////console.log(obj)
    
    if((obj.Baseallocation+obj.Flex)==0)
     return 'n'

    var total_moved_perc= ((obj.backlog_moved+obj.tw_received_twcob)/(obj.Baseallocation+obj.Flex))*100;
    // ////console.log(obj.Lane,obj.PARTNER,total_moved_perc,obj.Baseallocation,obj.Flex);
    
    if(this.overallocation_threshold_perc<=total_moved_perc)
     return 'o'
    else  if(this.warning_threshold_perc<=total_moved_perc)
     return 'w';
    else 
     return 'n'

     
  }
  get_threshold_rec(obj){
    if((obj.Baseallocation+obj.Flex)==0)
    return 'n'
   // //////////console.log(obj)
   return(((obj.tw_received+obj.backlog_received)+(obj.backlog_moved+obj.tw_received_twcob))/(obj.Baseallocation+obj.Flex))*100;

  }
  
  check_threshold_rec(obj){
    if((obj.Baseallocation+obj.Flex)==0)
     return 'n'
    // //////////console.log(obj)
    var total_moved_perc= (((obj.tw_received+obj.backlog_received)+(obj.backlog_moved+obj.tw_received_twcob))/(obj.Baseallocation+obj.Flex))*100;
    //  ////////console.log(total_moved_perc,obj.tw_received,obj.tw_received_twcob);
    
    if(this.overallocation_threshold_perc<=total_moved_perc)
     return 'o'
    else  if(this.warning_threshold_perc<=total_moved_perc)
     return 'w';
    else 
     return 'n'

     
  }
  // check_threshold_rec(obj){
  //   // //////////console.log(obj)
  //   var total_rec_perc= ((obj.tw_received+obj.tw_received_twcob)/this.getallocation(obj.PARTNER))*100;
  //   //  //////////console.log(total_moved_perc);
    
  //   if(this.overallocation_threshold_perc<=total_rec_perc)
  //    return 'o'
  //   else  if(this.warning_threshold_perc<=total_rec_perc)
  //    return 'w';
  //   else 
  //    return 'n'

     
  // }
  getBacklogReceivedperc(l){

    var total_bar_value= this.get_bar_value(l);
    return  ((l.backlog_received/total_bar_value)*100);

  }
  getBacklogReceivedperc_rec(l){

    var total_bar_value= this.get_bar_value_rec(l);
    return  ((l.backlog_received/total_bar_value)*100);

  }
  getallocationPercBaseBar(l)
  {
    var total_bar_value= this.get_bar_value(l);
    // return  ((this.getallocationBase_network(l.PARTNER,l.Lane)/total_bar_value)*100);
    return (((l.Baseallocation)/total_bar_value)*100);
  }
  getallocationPercBaseBar_rec(l)
  {
    var total_bar_value= this.get_bar_value_rec(l);
    // return  ((this.getallocationBase_network(l.PARTNER,l.Lane)/total_bar_value)*100);
    return (((l.Baseallocation)/total_bar_value)*100);
  }

  getReceivedperc(l){
    var total_bar_value= this.get_bar_value(l);
    return  ((l.tw_received/total_bar_value)*100);
  }
  getReceivedperc_rec(l){
    var total_bar_value= this.get_bar_value_rec(l);
    return  ((l.tw_received/total_bar_value)*100);
  }
  get_margin_recmoved(l)
  {
   return this.getBacklogmovedperc(l)
  }
  get_margin_backlogrec(l)
  {
    return this.getBacklogmovedperc(l)+this.getReceivedmovedperc(l)
  }
  get_margin_backlog(l)
  {
    return this.getBacklogmovedperc(l)+this.getReceivedmovedperc(l)+this.getBacklogReceivedperc(l)
  }
  get_margin_rec(l)
  {
    return this.getBacklogmovedperc(l)+this.getReceivedmovedperc(l)+this.getBacklogReceivedperc(l)
  }
  get_bar_value(l){
    // var allocation = this.getallocation_network(l.PARTNER,l.Lane);
    var allocation = (l.Baseallocation+l.Flex);
    // ////////console.log(l.backlog_moved,l.tw_received_twcob,l.tw_received,l.backlog_moved)
    var totalvalue= l.backlog_moved+l.tw_received_twcob+l.tw_received+l.backlog_received;
    //  ////////console.log(l.Lane,l.PARTNER,totalvalue); 

    // total_moveded / base + flex / 
    if(allocation<totalvalue)
       return totalvalue
    else
      return allocation
  }

  get_bar_value_rec(l){
    // var allocation = this.getallocation_network(l.PARTNER,l.Lane);
    var allocation = (l.Baseallocation+l.Flex);
    // ////////console.log(l.backlog_moved,l.tw_received_twcob,l.tw_received,l.backlog_moved)
    var totalvalue= l.total_received;
    // ////console.log(l.total_received,l.pw_received,allocation)
    //  ////////console.log(l.Lane,l.PARTNER,totalvalue); 

    // total_moveded / base + flex / 
    if(allocation<totalvalue)
       return totalvalue+l.pw_received
    else
      return allocation+l.pw_received
  }
  getBacklogmovedperc(l){


     var total_bar_value= this.get_bar_value(l);
    //  ////////console.log(l.backlog_moved,total_bar_value);

     return  ((l.backlog_moved/total_bar_value)*100);

  }
  getBacklogmovedperc_rec(l){


    var total_bar_value= this.get_bar_value_rec(l);

   //  ////////console.log(l.backlog_moved,total_bar_value);

    return  ((l.backlog_moved/total_bar_value)*100);

 }
 
 getbaseallocation(l){
  var allocation = (l.Baseallocation+l.Flex);
  var allocation1 = (l.Baseallocation);

  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
    total_bar_value= l.backlog_moved+l.tw_received_twcob;
  var total_bar_value1=total_bar_value

  if(total_bar_value<allocation)
  {
    total_bar_value=allocation
    
    
   var  allocationperc=  (((allocation1)/total_bar_value)*100);
  //  k['gettotalmovedperc']=this.gettotalmovedperc(l);
  //  k['gettotalbacklogperc']=this.gettotalbacklogperc(l);
  //  k['getminallocationdif']=this.getminallocationdif(l);

   if(allocationperc>(this.gettotalmovedperc(l)+this.gettotalbacklogperc(l)+this.getminallocationdif(l)))
     return  allocationperc-(this.gettotalmovedperc(l)+this.gettotalbacklogperc(l)+this.getminallocationdif(l));
  else
     return 0;

  }
  else{
    var  allocationperc=  (((allocation1)/total_bar_value)*100);
    //  k['gettotalmovedperc']=this.gettotalmovedperc(l);
    //  k['gettotalbacklogperc']=this.gettotalbacklogperc(l);
    //  k['getminallocationdif']=this.getminallocationdif(l);
  
     if(allocationperc>(this.gettotalmovedperc(l)+this.gettotalbacklogperc(l)+this.getminallocationdif(l)))
       return  allocationperc-(this.gettotalmovedperc(l)+this.gettotalbacklogperc(l)+this.getminallocationdif(l));
    else
       return 0;


  }

 }

 getoverallocationdetails(l){
  // console.log(l)

   var k:any={'gettotalmovedperc':0,'gettotalbacklogperc':0,'getminallocationdif':0,'getminallocationdif_v':0,'getminallocationdif_spend':0,'getoverallocationdif_a':0,'getoverallocationdif_a_v':0,'getoverallocationdif_a_s':0,'getoverallocationdif_p':0,'getoverallocationdif_p_v':0,'getoverallocationdif_p_s':0}
   k['gettotalmovedperc']=this.gettotalmovedperc(l);
   k['gettotalbacklogperc']=this.gettotalbacklogperc(l);
   k['getminallocationdif']=this.getminallocationdif(l);

   k['getminallocationdif_v']=this.getminallocationdif_v(l);
   k['getminallocationdif_spend']=this.getminallocationdif_spend(l);
   k['getoverallocationdif_a']=this.getoverallocationdif_a(l);
   k['getoverallocationdif_a_v']=this.getoverallocationdif_a_v(l);
   k['getoverallocationdif_a_s']=this.getoverallocationdif_a_s(l);
   k['getoverallocationdif_p']=this.getoverallocationdif_p(l);
   k['getoverallocationdif_p_v']=this.getoverallocationdif_p_v(l);
   k['getoverallocationdif_p_s']=this.getoverallocationdif_p_s(l);
   k['getbaseallocation']=this.getbaseallocation(l);

   return k;


 }
 gettotalmovedperc(l){
  var allocation = (l.Baseallocation+l.Flex);
  
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
    total_bar_value= l.backlog_moved+l.tw_received_twcob;
  var total_bar_value1=total_bar_value

  if(total_bar_value<allocation)
  {
    total_bar_value=allocation
    return  (((l.backlog_moved+l.tw_received_twcob)/total_bar_value)*100);

  }
  else{

    if(!this.currentweekflag_w)
      {
        var overallocation= total_bar_value-allocation;


          return (allocation/total_bar_value)*100;
       

      }
      else{

      
    var overallocation= total_bar_value-allocation;
    // console.log('b',overallocation,total_bar_value,allocation)
    var boverallocation=0;
    if(overallocation<(l.tw_received+l.backlog_received)){
      return (((l.backlog_moved+l.tw_received_twcob))/total_bar_value)*100
    }
    else
     return ((allocation)/total_bar_value)*100
     }
  }
  // if((total_bar_value1-allocation)>0)
  // {
  //   if((l.backlog_moved+l.tw_received_twcob)>allocation)
  //     return allocation;
  //   else 
  //     return  (((l.backlog_moved+l.tw_received_twcob)/total_bar_value)*100);

  // }else

}
getminallocationdif(l){
  var allocation = (l.Baseallocation+l.Flex);
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
    total_bar_value= l.backlog_moved+l.tw_received_twcob;

  var total_bar_value1=total_bar_value
  if(total_bar_value<allocation)
    total_bar_value=allocation
 
  if ((l.MinAllocaion-total_bar_value1)>0)
  {
    return (((l.MinAllocaion-total_bar_value1)/total_bar_value)*100);
  }else{

   return  0;
  }
}
getminallocationdif_v(l){
  var allocation = (l.Baseallocation+l.Flex);
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
    total_bar_value= l.backlog_moved+l.tw_received_twcob;

  var total_bar_value1=total_bar_value
  if(total_bar_value<allocation)
    total_bar_value=allocation
 
  if ((l.MinAllocaion-total_bar_value1)>0)
  {
    return (((l.MinAllocaion-total_bar_value1)));
  }else{
  return  0;
  }
}
getminallocationdif_spend(l){
  var allocation = (l.Baseallocation+l.Flex);
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
    total_bar_value= l.backlog_moved+l.tw_received_twcob;
  
  var total_bar_value1=total_bar_value
  if(total_bar_value<allocation)
    total_bar_value=allocation
 
  if ((l.MinAllocaion-total_bar_value1)>0)
  {
    return (((l.MinAllocaion-total_bar_value1)*this.getallocation_spend_details_baserate(l.Lane,l.PARTNER)));
  }else{
  return  0;
  }
}
setsumflag(f){
  this.receivedFlag=f;
  this.Penaltyflag=false;

}
setpenalty(){
  this.Penaltyflag=true;
}
getoverallocationdif_a(l){
  var allocation = (l.Baseallocation+l.Flex);
  
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  
  if(!this.currentweekflag_w)
   total_bar_value= l.backlog_moved+l.tw_received_twcob

  var total_bar_value1=total_bar_value;
  if(total_bar_value<allocation)
   {
     total_bar_value=allocation
     return 0;
   } 
   else
   {
    
     var overallocation= (total_bar_value-allocation);
     var a_p=0;
     if((l.backlog_moved+l.tw_received_twcob)>allocation)
      var a_p= (l.backlog_moved+l.tw_received_twcob)-allocation;
    


     return  (a_p/total_bar_value)*100;
   } 
  

  // if(total_bar_value<allocation)
  //   total_bar_value=allocation
 
  // if ((total_bar_value1-allocation)>0)
  // {
  //   return (((l.MinAllocaion-total_bar_value1)/total_bar_value)*100);
  // }else{
  // return  0;
  // }
}
getoverallocationdif_a_v(l){
  var allocation = (l.Baseallocation+l.Flex);
  
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  
  if(!this.currentweekflag_w)
   total_bar_value= l.backlog_moved+l.tw_received_twcob
  var total_bar_value1=total_bar_value;
  if(total_bar_value<allocation)
   {
     total_bar_value=allocation
     return 0;
   } 
   else
   {
    
     var overallocation= (total_bar_value-allocation);
     var a_p=0;
     if((l.backlog_moved+l.tw_received_twcob)>allocation)
      var a_p= (l.backlog_moved+l.tw_received_twcob)-allocation;

     return  a_p;
   } 
  }
getoverallocationdif_a_s(l){
    var allocation = (l.Baseallocation+l.Flex);
    
    var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
    
    if(!this.currentweekflag_w)
     total_bar_value= l.backlog_moved+l.tw_received_twcob
    var total_bar_value1=total_bar_value;
    if(total_bar_value<allocation)
     {
       total_bar_value=allocation
       return 0;
     } 
     else
     {
      
       var overallocation= (total_bar_value-allocation);
       var a_p=0;
       if((l.backlog_moved+l.tw_received_twcob)>allocation)
        var a_p= (l.backlog_moved+l.tw_received_twcob)-allocation;
  
       return  a_p*this.getallocation_spend_details_prate(l.Lane,l.PARTNER);
     } 
   
  // if(total_bar_value<allocation)
  //   total_bar_value=allocation
 
  // if ((total_bar_value1-allocation)>0)
  // {
  //   return (((l.MinAllocaion-total_bar_value1)/total_bar_value)*100);
  // }else{
  // return  0;
  // }
}
getoverallocationdif_p(l){
  var allocation = (l.Baseallocation+l.Flex);
  
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
   total_bar_value= l.backlog_moved+l.tw_received_twcob
  var total_bar_value1=total_bar_value

  if(total_bar_value<allocation)
   {
     total_bar_value=allocation
     return 0;
   } 
   else
   {
    var overallocation= total_bar_value1-allocation;

    var a_p=0;
     if((l.backlog_moved+l.tw_received_twcob)>allocation)
      var a_p= (l.backlog_moved+l.tw_received_twcob)-allocation;
   
     var p_p= overallocation-a_p;

     return  (p_p/total_bar_value)*100;
   } 
  

  // if(total_bar_value<allocation)
  //   total_bar_value=allocation
 
  // if ((total_bar_value1-allocation)>0)
  // {
  //   return (((l.MinAllocaion-total_bar_value1)/total_bar_value)*100);
  // }else{
  // return  0;
  // }
}
getoverallocationdif_p_v(l){
  var allocation = (l.Baseallocation+l.Flex);
  
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
   total_bar_value= l.backlog_moved+l.tw_received_twcob
  var total_bar_value1=total_bar_value

  if(total_bar_value<allocation)
   {
     total_bar_value=allocation
     return 0;
   } 
   else
   {
    var overallocation= total_bar_value1-allocation;

     var a_p=0;
     if((l.backlog_moved+l.tw_received_twcob)>allocation)
      var a_p= (l.backlog_moved+l.tw_received_twcob)-allocation;
    var p_p= overallocation-a_p;

     return p_p;
   } 
  

  // if(total_bar_value<allocation)
  //   total_bar_value=allocation
 
  // if ((total_bar_value1-allocation)>0)
  // {
  //   return (((l.MinAllocaion-total_bar_value1)/total_bar_value)*100);
  // }else{
  // return  0;
  // }
}
getoverallocationdif_p_s(l){
  var allocation = (l.Baseallocation+l.Flex);
  
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
   total_bar_value= l.backlog_moved+l.tw_received_twcob
  var total_bar_value1=total_bar_value

  if(total_bar_value<allocation)
   {
     total_bar_value=allocation
     return 0;
   } 
   else
   {
    var overallocation= total_bar_value1-allocation;
    var a_p=0;
     if((l.backlog_moved+l.tw_received_twcob)>allocation)
      var a_p= (l.backlog_moved+l.tw_received_twcob)-allocation;

    var p_p= overallocation-a_p;

     return p_p*this.getallocation_spend_details_prate(l.Lane,l.PARTNER);
   } 
  

  // if(total_bar_value<allocation)
  //   total_bar_value=allocation
 
  // if ((total_bar_value1-allocation)>0)
  // {
  //   return (((l.MinAllocaion-total_bar_value1)/total_bar_value)*100);
  // }else{
  // return  0;
  // }
}


gettotalbacklogperc(l){
  var allocation = (l.Baseallocation+l.Flex);
  var total_bar_value= l.backlog_moved+l.backlog_received+l.tw_received+l.tw_received_twcob;
  if(!this.currentweekflag_w)
    {  
      
      var total_bar_value= l.backlog_moved+l.tw_received_twcob;


     return 0;
    } 
  else{  

        if(total_bar_value<allocation)
        {

          total_bar_value=allocation
          
            return  ((((l.tw_received+l.backlog_received))/total_bar_value)*100); 
        }
        else{

          var overallocation= total_bar_value-allocation;
          if(overallocation<(l.tw_received+l.backlog_received)){
            return (((l.tw_received+l.backlog_received)-overallocation)/total_bar_value)*100
          }
          else
          return 0;
        }
  }
  
}

  getReceivedmovedperc(l){
    var total_bar_value= this.get_bar_value(l);
    return  ((l.tw_received_twcob/total_bar_value)*100);
  }
  getReceivedmovedperc_rec(l){
    var total_bar_value= this.get_bar_value_rec(l);
    return  ((l.tw_received_twcob/total_bar_value)*100);
  }
  getallocationFlex(partner)
  {
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    var lane= this.partner_allocation.filter(function(item:any){return item.Partner===partner && item.Origin===origin && item.Destination===destination}) 
    if(lane.length>0)
     return lane[0].Flex
    else {
       return 0;
    }
  }
  check_allocation(partner,lane){

    var origin = lane.split("-")[0]
    var destination = lane.split("-")[1]
    
    var laned= this.partner_allocation.filter(function(item:any){return item.PARTNER===partner && item.Origin===origin && item.Destination===destination}) 
    if(laned.length>0)
     return false
    else {
       return true;
     }
  }
  distinctdate=[]
  distinctdatestr=[]

  getdistinctdates(){
    this.distinctdate = this.data_result.df_all
        .map((item) => item.FREIGHT_RCVD_DATE)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
        //////////console.log(this.distinctdate)

        
   
        this.distinctdate.sort();
  }
  distinctpartner=[]
  getdistinctpartner(){
    var list=JSON.parse(JSON.stringify(this.data_result.df_all));
    this.data_result.df_all_cob.forEach(element => {
      
      list.push(element)
    });
    this.distinctpartner = list
        .map((item) => item.PARTNER)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
        //////////console.log(this.distinctpartner)
   
        this.distinctpartner.sort();
  }

  servicelevel=[]
  getdistinctservicelevel(){
    var list=JSON.parse(JSON.stringify(this.data_result.df_all));
    this.data_result.df_all_cob.forEach(element => {
      
      list.push(element)
    });
    this.servicelevel = list
        .map((item) => item['Service Level'])
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
        //////////console.log(this.distinctpartner)
   
        // this.distinctpartner.sort();
  }
  days=["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
  daily_totals_graph:any;
  allocation_linechart_s:any;

  back_log=[];
  
  backlog_by_partner:any=[]
  backlog_by_service:any=[]

  getDailybacklog(){
    // var origin=this.filterselection.selectedlane.split("-")[0];
    // var destination=this.filterselection.selectedlane.split("-")[1];

    this.back_log=[]

    
    // var =this.data_result.
    //////////console.log(this.backlog_received)
    
    var totalbiging_balance=this.pw_received;
    

    //  var startdate= moment(this.filterselection.date[0].Date, 'MM/DD/YYYY')
    
    this.backlog_by_partner=[]
    this.back_log=[]

    this.backlog_by_service=[]
    this.back_log=[]
    ////console.log(this.data_result.output_json)
    this.data_result.output_json.forEach(elementp => {

      var biging_balance=elementp.pw_received;
    
      var backlog=[]

     
      
    this.filterselection.date.forEach(element => {

      const date = moment(element.Date, 'MM/DD/YYYY')

      // myMomentObject.format('MM/DD/YYYY')===myMomentObject1.format('MM/DD/YYYY'


          var prec=0;
          var pmoved=0;

          var list = this.data_result.df_all.filter(function(item:any){
          
          const rec = moment(item.FREIGHT_RCVD_DATEbk, 'MM/DD/YYYY')
          return  rec.isSame(date) && item.PARTNER==elementp.PARTNER;
        
        })

          var list_cob = this.data_result.df_all_cob.filter(function(item:any){
          
            const cob = moment(item.COB_DATEbk, 'MM/DD/YYYY')
            return  cob.isSame(date) && item.PARTNER==elementp.PARTNER;
          })


          list.forEach(recl => {
             
            prec = prec+recl.COB_WEIGHT_CHARGEABLE;
          });
          list_cob.forEach(recl => {
             
            pmoved = pmoved+recl.COB_WEIGHT_CHARGEABLE;
          });



          biging_balance=biging_balance+prec-pmoved;
          backlog.push({date:date,backlog:biging_balance});

         


       });

       this.backlog_by_partner.push({partner:elementp.PARTNER,backlog:backlog})

       ////console.log(this.backlog_by_partner)


       



        // var list_rec = this.data_result.df_all.filter(function(item:any){
        
        //   const cob = moment(item.COB_DATEbk, 'MM/DD/YYYY')
        //   const rec = moment(item.FREIGHT_RCVD_DATEbk, 'MM/DD/YYYY')
  
        //   // //////////console.log(cob.format('MM/DD/YYYY'),date.format('MM/DD/YYYY') ,rec.format('MM/DD/YYYY') ,startdate.format('MM/DD/YYYY'))
         
        //   return  rec.isSame(date)})

        // // //////////console.log
        //   var back_log=0;
        //   list.forEach(element => {
        //     back_log=back_log+element.COB_WEIGHT_CHARGEABLE;

        //   });

        //   var rec_total=0;
        //   list_rec.forEach(element => {
        //     rec_total=rec_total+element.COB_WEIGHT_CHARGEABLE;

        //   });

        //   var back_log_partner:any=[]
        //   this.distinctpartner.forEach(element => {
            
        //     var listbacklog_d= list.filter(function(item:any){return item.PARTNER===element})
        //     var back=0;
        //     listbacklog_d.forEach(element => {
        //         back=back+element.COB_WEIGHT_CHARGEABLE             

        //     });
        //     var listbacklog_d_rec= list_rec.filter(function(item:any){return item.PARTNER===element})
        //     var rec=0;
        //     listbacklog_d_rec.forEach(element => {
        //         rec=rec+element.COB_WEIGHT_CHARGEABLE             

        //     });
        //     back_log_partner.push({"partner":element,"backlog":back})

        //   });

      

    });

    this.back_log=[]
    var k=0;
    this.filterselection.date.forEach(el => {

        var tback_log=0
        this.backlog_by_partner.forEach(element => {

          // ////////console.log(element);

          tback_log=tback_log + element.backlog[k].backlog;
          
          
        });
        this.back_log.push({backlog:tback_log})
        k=k+1;
    });
    ////////console.log(this.back_log)
  }

  partner_daily_graph:any;
  partner_daily_graph_rec:any;

  partner_daily_graph_backlog:any;

  partner_daily_graph_C:any;
  partner_daily_graph_rec_C:any;

  partner_daily_graph_backlog_C:any;
  daily_partner_graph_flag='moved'
  daily_partner_graph_flag_serv='moved'

  barchart_partner_Table:any=[]

  service_partner_daily_graph:any;
  service_partner_daily_graph_rec:any;

  service_partner_daily_graph_backlog:any;

  service_partner_daily_graph_C:any;
  service_partner_daily_graph_rec_C:any;

  service_partner_daily_graph_backlog_C:any;
  generatelistforgrap(){

    var temp = []
    var temp_C = []
    this.barchart_partner_Table=[]
    temp.push(["Day","Total Moved",{role:"annotation"},"Total Received",{role:"annotation"}])
    this.barchart_partner_Table.push(["Day","Total Moved","Total Received"])
    temp_C.push(["Day","Total Moved","Total Backlog",{role:"annotation"},"Total Received","Total Available"])

    var temp_p_moved:any=["Day"];
    var temp_p_received:any=["Day"];
    var temp_p_backlog:any=["Day"];

    

    var temp_partner_moved=[]
    var temp_partner_rec=[]
    var temp_partner_backlog=[]

    var temp_partner_moved_C=[]
    var temp_partner_rec_C=[]
    var temp_partner_backlog_C=[]


    // service
    var serv_temp_p_moved:any=["Day"];
    var serv_temp_p_received:any=["Day"];
    var serv_temp_p_backlog:any=["Day"];

    

    var serv_temp_partner_moved=[]
    var serv_temp_partner_rec=[]
    var serv_temp_partner_backlog=[]

    var serv_temp_partner_moved_C=[]
    var serv_temp_partner_rec_C=[]
    var serv_temp_partner_backlog_C=[]


    //console.log(this.distinctpartner)

    this.distinctpartner.forEach(element => {
      temp_p_moved.push(element)
      temp_p_received.push(element)
      temp_p_backlog.push(element)
    });


    this.servicelevel.forEach(element => {
      serv_temp_p_moved.push(element)
      serv_temp_p_received.push(element)
      serv_temp_p_backlog.push(element)
    });


    temp_p_moved.push({role:"annotation"});
    temp_p_received.push({role:"annotation"});
    temp_p_backlog.push({role:"annotation"});

    
    


    temp_partner_moved.push(temp_p_moved);
    temp_partner_rec.push(temp_p_received);
    temp_partner_backlog.push(temp_p_backlog);

    // serviceleve

    serv_temp_p_moved.push({role:"annotation"});
    serv_temp_p_received.push({role:"annotation"});
    serv_temp_p_backlog.push({role:"annotation"});

    serv_temp_partner_moved.push(serv_temp_p_moved);
    serv_temp_partner_rec.push(serv_temp_p_received);
    serv_temp_partner_backlog.push(serv_temp_p_backlog);

    
    // temp_partner_moved_C.push(temp_p_moved);
    // temp_partner_rec_C.push(temp_p_moved);
    // temp_partner_backlog_C.push(temp_p_backlog);

    var i=0
    var total_moved=0;
    var total_received=0;
    var total_backlog=0;

   var ctx=this;

    for(var i=0;i<this.filterselection.date.length;i++)
    {


     var element =this.filterselection.date[i];
      var dates=element.Date
      //////////console.log(element.Date)
      var list= this.data_result.df_all.filter(function(item:any){
        // //////////console.log("date",element.Date,item.FREIGHT_RCVD_DATEbk)
        const myMomentObject = moment(item.FREIGHT_RCVD_DATEbk, 'MM/DD/YYYY')
        const myMomentObject1 = moment(element.Date, 'MM/DD/YYYY')
        // //////////console.log("date",element.Date,item.FREIGHT_RCVD_DATEbk,myMomentObject.format('MM/DD/YYYY'),myMomentObject1.format('MM/DD/YYYY'));

        return myMomentObject.format('MM/DD/YYYY')===myMomentObject1.format('MM/DD/YYYY')

      })


      
 


      // //////////console.log(list);

      var received=0;
      list.forEach(el => {

        received = received +el.COB_WEIGHT_CHARGEABLE

      });

      var listmoved= this.data_result.df_all_cob.filter(function(item:any){
        const myMomentObject = moment(item.COB_DATEbk, 'MM/DD/YYYY')
        const myMomentObject1 = moment(element.Date, 'MM/DD/YYYY')
        return myMomentObject.format('MM/DD/YYYY')===myMomentObject1.format('MM/DD/YYYY')});
 
     ////console.log(listmoved);

      var moved=0;
      listmoved.forEach(el => {

        moved = moved +el.COB_WEIGHT_CHARGEABLE

      });
      total_moved=total_moved+moved;
      total_backlog=total_backlog+this.back_log[i].backlog
      
      total_received=total_received+received;
      
      temp.push([this.days[i],moved,moved.toLocaleString(this.lc,this.opt),received,received.toLocaleString(this.lc,this.opt)])
      this.barchart_partner_Table.push([this.days[i],moved,received])
      temp_C.push([this.days[i],total_moved,this.back_log[i].backlog,this.back_log[i].backlog.toLocaleString(this.lc,this.opt),total_received,this.getallocation_lane(this.filterselection.selectedlane)])


       var t=[];

       t.push(this.days[i])

       var t_rec=[];
       var t_backlog=[];

       t_rec.push(this.days[i])
       t_backlog.push(this.days[i])

     
      // ["Date","MOved","Receved"],["dafadf","a1232",]]

      var k=0;
      var t_momvedv=0;
      var t_recv=0;
      var t_backlogv=0;
      this.distinctpartner.forEach(element => {

        var listmoved_d= listmoved.filter(function(item:any){return item.PARTNER===element})

        var moved_p=0
        var received_p=0
        listmoved_d.forEach(element => {
          moved_p=moved_p+element.COB_WEIGHT_CHARGEABLE
          
        });

        var listrec_d= list.filter(function(item:any){return item.PARTNER===element})

        listrec_d.forEach(element => {

          received_p=received_p+element.COB_WEIGHT_CHARGEABLE

        });
        

        ////console.log(ctx.backlog_by_partner)
        ////console.log(element)
        var back_log_p= ctx.backlog_by_partner.filter(function(item:any){return item.partner===element})

        // ////console.log(back_log_p)
        t.push(moved_p);
        t_rec.push(received_p)
        if(back_log_p.length>0)
          {
            t_backlog.push(back_log_p[0].backlog[i].backlog)
        t_momvedv=t_momvedv+moved_p;
        t_recv=t_recv+received_p;
        t_backlogv=t_backlogv+back_log_p[0].backlog[i].backlog;
          }
          else{
            t_backlog.push(0)
            t_momvedv=t_momvedv+moved_p;
            t_recv=t_recv+received_p;
            t_backlogv=t_backlogv+0;
          }
        
        //////////console.log(moved_p)


        // if(i>0)
        //   t_c.push(moved_p+temp_partner_moved[i][k]);
        // else
        //   t_c.push(moved_p);

        // t_rec_c.push(received_p)

        // t_backlog_c.push(this.back_log[i].partner[0].backlog)
        // // if(i>1)
        // // {
        //   //////////console.log(moved_p,temp_partner_moved_C[i-1][k])
        //   // t_c.push(moved_p+temp_partner_moved_C[i-1][k]);
        //   // t_rec_c.push(received_p+temp_partner_rec_C[i-1][k])
        //   // t_backlog_c.push(this.back_log[i].partner[0].backlog+temp_partner_backlog_C[i-1][k])
        // }
        // else{
        //   // t_c.push(moved_p);
        //   // t_rec_c.push(received_p)
        //   // t_backlog_c.push(this.back_log[i].partner[0].backlog)
        // }
         k=k+1;
      }); 

     


      t.push(t_momvedv.toLocaleString(this.lc,this.opt))
      t_rec.push(t_recv.toLocaleString(this.lc,this.opt))
      t_backlog.push(t_backlogv.toLocaleString(this.lc,this.opt))

      temp_partner_moved.push(t);
      temp_partner_rec.push(t_rec);
      temp_partner_backlog.push(t_backlog);
      


    // service level

    var t=[];

       t.push(this.days[i])

       var t_rec=[];
       var t_backlog=[];

       t_rec.push(this.days[i])
       t_backlog.push(this.days[i])

     
      // ["Date","MOved","Receved"],["dafadf","a1232",]]

      var k=0;
      var t_momvedv=0;
      var t_recv=0;
      var t_backlogv=0;
      this.servicelevel.forEach(element => {

        var listmoved_d= listmoved.filter(function(item:any){return item['Service Level']===element})

        var moved_p=0
        var received_p=0
        listmoved_d.forEach(element => {
          moved_p=moved_p+element.COB_WEIGHT_CHARGEABLE
          
        });

        var listrec_d= list.filter(function(item:any){return item['Service Level']===element})

        listrec_d.forEach(element => {

          received_p=received_p+element.COB_WEIGHT_CHARGEABLE

        });
        

        var back_log_p= this.backlog_by_service.filter(function(item:any){return item.service===element})

        // ////////console.log(back_log_p)
        t.push(moved_p);
        t_rec.push(received_p)
        // 
        t_backlog.push(100)
        t_momvedv=t_momvedv+moved_p;
        t_recv=t_recv+received_p;
        t_backlogv=t_backlogv+100;
        //////////console.log(moved_p)


        // if(i>0)
        //   t_c.push(moved_p+temp_partner_moved[i][k]);
        // else
        //   t_c.push(moved_p);

        // t_rec_c.push(received_p)

        // t_backlog_c.push(this.back_log[i].partner[0].backlog)
        // // if(i>1)
        // // {
        //   //////////console.log(moved_p,temp_partner_moved_C[i-1][k])
        //   // t_c.push(moved_p+temp_partner_moved_C[i-1][k]);
        //   // t_rec_c.push(received_p+temp_partner_rec_C[i-1][k])
        //   // t_backlog_c.push(this.back_log[i].partner[0].backlog+temp_partner_backlog_C[i-1][k])
        // }
        // else{
        //   // t_c.push(moved_p);
        //   // t_rec_c.push(received_p)
        //   // t_backlog_c.push(this.back_log[i].partner[0].backlog)
        // }
         k=k+1;
      }); 

     


      t.push(t_momvedv.toLocaleString(this.lc,this.opt))
      t_rec.push(t_recv.toLocaleString(this.lc,this.opt))
      t_backlog.push(t_backlogv.toLocaleString(this.lc,this.opt))

      serv_temp_partner_moved.push(t);
      serv_temp_partner_rec.push(t_rec);
      serv_temp_partner_backlog.push(t_backlog);


      // i=i+1;
      
     
          const date = moment(element.Date, 'MM/DD/YYYY')
          //////////console.log(date.isSame(this.today),date.format("MM/DD/YYYY"),this.today.format("MM/DD/YYYY"))
          if(date.format("MM/DD/YYYY")===this.today.format("MM/DD/YYYY"))
            {
                //////////console.log("matched");
                break;
              
            }
     
      

    }

    var t_c=[];

    t_c.push(this.days[i])

    var t_rec_c=[];
    var t_backlog_c=[];

    t_rec_c.push(this.days[i])
    t_backlog_c.push(this.days[i])

    var j=0
    temp_partner_moved.forEach(element => {
      
     
       if(j<=1)
      {
        temp_partner_moved_C.push(element.slice(0,element.length-1))
        
        if(j==0)
          {
            temp_partner_moved[0].slice(1,element.length-1).forEach(elementk => {
              temp_partner_moved_C[0].push(elementk)

            });
          }
          else
          {
            temp_partner_moved[0].slice(1,element.length-1).forEach(elementk => {
              temp_partner_moved_C[j].push(this.getallocation_n(elementk))
            });  
          }
      }
      else{

        var t=[];
        t.push(element[0]);
        for(var m=1;m<element.length-1;m++)
          t.push(element[m]+temp_partner_moved_C[j-1][m])
       
          temp_partner_moved[0].slice(1,element.length-1).forEach(elementk => {
            t.push(this.getallocation_n(elementk))
          });  
          temp_partner_moved_C.push(t)

      }
    

      j=j+1;
    });

    ////console.log(temp_partner_moved_C)
    var j=0
    temp_partner_rec.forEach(element => {
   
       if(j<=1)
      {
        temp_partner_rec_C.push(element.slice(0,element.length-1))
        if(j==0)
        {
          temp_partner_rec[0].slice(1,element.length-1).forEach(elementk => {
            temp_partner_rec_C[0].push(elementk)

          });
        }
        else
        {
          temp_partner_rec[0].slice(1,element.length-1).forEach(elementk => {
            temp_partner_rec_C[j].push(this.getallocation_n(elementk))
          });  
        }
        
      }
      else{

        var t=[];
        t.push(element[0]);
        for(var m=1;m<element.length-1;m++)
          t.push(element[m]+temp_partner_rec_C[j-1][m])

          temp_partner_rec[0].slice(1,element.length-1).forEach(elementk => {
            t.push(this.getallocation_n(elementk))
          });  
          temp_partner_rec_C.push(t)

      }

      

    
      j=j+1;
    });

    var j=0
    temp_partner_backlog.forEach(element => {
      
       if(j<=1)
      {
        temp_partner_backlog_C.push(element.slice(0,element.length-1))

        if(j==0)
        {
          temp_partner_backlog[0].slice(1,element.length-1).forEach(elementk => {
            temp_partner_backlog_C[0].push(elementk)

          });
        }
        else
        {
          temp_partner_backlog[0].slice(1,element.length-1).forEach(elementk => {
            temp_partner_backlog_C[j].push(this.getallocation_n(elementk))
          });  
        }
      }
      else{

        var t=[];
        t.push(element[0]);
        for(var m=1;m<element.length-1;m++)
          t.push(element[m])
        
         temp_partner_backlog[0].slice(1,element.length-1).forEach(elementk => {
            t.push(this.getallocation_n(elementk))
          }); 
          temp_partner_backlog_C.push(t)

      }
    

      j=j+1;

    
       
      
   
    });


    // service
    var t_c=[];

    t_c.push(this.days[i])

    var t_rec_c=[];
    var t_backlog_c=[];

    t_rec_c.push(this.days[i])
    t_backlog_c.push(this.days[i])

    var j=0
    serv_temp_partner_moved.forEach(element => {
      
     
       if(j<=1)
      {
        serv_temp_partner_moved_C.push(element.slice(0,element.length-1))
      }
      else{

        var t=[];
        t.push(element[0]);
        for(var m=1;m<element.length-1;m++)
          t.push(element[m]+serv_temp_partner_moved_C[j-1][m])

          serv_temp_partner_moved_C.push(t)

      }
    

      j=j+1;
    });

    ////console.log(temp_partner_moved_C)
    var j=0
    serv_temp_partner_rec.forEach(element => {
   
       if(j<=1)
      {
        serv_temp_partner_rec_C.push(element.slice(0,element.length-1))
      }
      else{

        var t=[];
        t.push(element[0]);
        for(var m=1;m<element.length-1;m++)
          t.push(element[m]+serv_temp_partner_rec_C[j-1][m])

          serv_temp_partner_rec_C.push(t)

      }

    
      j=j+1;
    });

    var j=0
    serv_temp_partner_backlog.forEach(element => {
      
       if(j<=1)
      {
        serv_temp_partner_backlog_C.push(element.slice(0,element.length-1))
      }
      else{

        var t=[];
        t.push(element[0]);
        for(var m=1;m<element.length-1;m++)
          t.push(element[m])

          serv_temp_partner_backlog_C.push(t)

      }
    

      j=j+1;

    
       
      
   
    });

    //////////console.log(temp_partner_backlog_C)
    //  temp_partner_moved_C.push(t_c);
    //   temp_partner_rec_C.push(t_rec_c);
    //   temp_partner_backlog_C.push(t_backlog_c);


    // //////////console.log(temp_partner_moved_C);
    // //////////console.log(temp_partner_rec_C);
    // //////////console.log(temp_partner_backlog_C);

    var allocation_partnerdist = {
      chartType: 'ColumnChart',
      dataTable: temp,
       options: {
        
     
        options: {
  
          title: 'Monthly Spend Variance',
          
        },
        'chartArea': {'width': '80%', 'height': '75%'},

        annotations: {
          textStyle: {
          color:'black',
          fontSize:12
          
          },style: "Line"
        },
        // legend:'none',
        legend: { position: 'top', alignment: 'left' },

        bar: { groupWidth: "70%" },

        vAxis: {  gridlines: {
          color: 'transparent'
        },
        textPosition: 'none'
      },

      hAxis: {  gridlines: {
        color: 'transparent',

       }        
      },
  
    
        pointSize: 5,

        // vAxes: {
        //   // Adds titles to each axis.
        //   0: {title: 'Chargable Weeight'},
        // }, 
        colors: ['#2ECC71','#D5F5E3','']

        ,
      height:320,
      }
    }

    var allocation_linechart_s = {
      chartType: 'LineChart',
      dataTable: [],
       options: {
       
        'chartArea': {'width': '75%', 'height': '75%'},

        vAxis: {  gridlines: {
          color: 'transparent'
        },
      
      },
      
      legend:{ position: 'top', alignment: 'center' },
            
      hAxis: {  gridlines: {
        color: 'transparent'
       }},
       
        // hAxis:{format:'MM-dd-yyyy'},
        height:320,
        pointSize:3,

        colors: ['#2ECC71','#9B59B6','#D5F5E3']
        
       },
      

              
      }

      var allocation_linechart_s_partner = {
        chartType: 'LineChart',
        dataTable: [],
         options: {
         
          'chartArea': {'width': '75%', 'height': '75%'},
  
          vAxis: {  gridlines: {
            color: 'transparent'
          },
        
        },
        
        legend:{ position: 'top', alignment: 'center' },
              
        hAxis: {  gridlines: {
          color: 'transparent'
         }},
         
          // hAxis:{format:'MM-dd-yyyy'},
          height:320,
          pointSize:3,
  
          colors: ['#C6AC9C','#D1BAAC','#E1CFC4','#ECDFD7']
          
         },
        
  
                
        }
      var partner_daily = {
        chartType: 'ColumnChart',
        dataTable: temp,
         options: {
          
       
          options: {
    
            title: 'Monthly Spend Variance',
            
          },
          'chartArea': {'width': '80%', 'height': '75%'},
  
          annotations: {
            textStyle: {
            color:'black',
            fontSize:12
            },style: "Line"
          },
          // legend:'none',
          legend: { position: 'top', alignment: 'left' },
  
          bar: { groupWidth: "70%" },
  
          vAxis: {  gridlines: {
            color: 'transparent'
          },
          textPosition: 'none'
        },
  
        hAxis: {  gridlines: {
          color: 'transparent',
  
         }        
        },
    
      
          isStacked:"true",
          pointSize: 5,
  
          // vAxes: {
          //   // Adds titles to each axis.
          //   0: {title: 'Chargable Weeight'},
          // }, 
          colors: ['#C6AC9C','#D1BAAC','#E1CFC4','#ECDFD7']
  
          ,
        height:320,
        }
      }


      partner_daily.dataTable=temp_partner_moved;
      //console.log(partner_daily)

      
      this.partner_daily_graph=JSON.parse(JSON.stringify(partner_daily));

      partner_daily.dataTable=temp_partner_rec;
      this.partner_daily_graph_rec=JSON.parse(JSON.stringify(partner_daily));

      partner_daily.dataTable=temp_partner_backlog;
      this.partner_daily_graph_backlog=JSON.parse(JSON.stringify(partner_daily));

      //console.log(temp_partner_rec);

      var k=0;
       
      allocation_linechart_s_partner.options.colors=[]
      this.partner_color.slice(0,this.distinctpartner.length).forEach(element => {
        allocation_linechart_s_partner.options.colors.push(element)
      });
      this.partner_color.slice(0,this.distinctpartner.length).forEach(element => {
        allocation_linechart_s_partner.options.colors.push(element)
      });
      allocation_linechart_s_partner.options.colors
      allocation_linechart_s_partner.dataTable=temp_partner_moved_C;
      this.partner_daily_graph_C=JSON.parse(JSON.stringify(allocation_linechart_s_partner));

      allocation_linechart_s_partner.dataTable=temp_partner_rec_C;
      this.partner_daily_graph_rec_C=JSON.parse(JSON.stringify(allocation_linechart_s_partner));

      allocation_linechart_s_partner.dataTable=temp_partner_backlog_C;
      this.partner_daily_graph_backlog_C=JSON.parse(JSON.stringify(allocation_linechart_s_partner));


      
      




      allocation_linechart_s.dataTable=temp_C;
      this.allocation_linechart_s=JSON.parse(JSON.stringify(allocation_linechart_s));


      // allocation_linechart_s.dataTable=temp_C;
      // this.allocation_linechart_s=JSON.parse(JSON.stringify(allocation_linechart_s));

      // allocation_linechart_s.dataTable=temp_C;
      // this.allocation_linechart_s=JSON.parse(JSON.stringify(allocation_linechart_s));

      // allocation_linechart_s.dataTable=temp_C;
      // this.allocation_linechart_s=JSON.parse(JSON.stringify(allocation_linechart_s));


      allocation_partnerdist.dataTable=temp;
      this.daily_totals_graph=JSON.parse(JSON.stringify(allocation_partnerdist));



      // service

      partner_daily.dataTable=serv_temp_partner_moved;
      ////console.log(partner_daily)
      
      partner_daily.options.colors=['#DC7633','#E59866','#F6DDCC']
      this.service_partner_daily_graph=JSON.parse(JSON.stringify(partner_daily));

      partner_daily.dataTable=serv_temp_partner_rec;

      this.service_partner_daily_graph_rec=JSON.parse(JSON.stringify(partner_daily));

      partner_daily.dataTable=serv_temp_partner_backlog;
      this.service_partner_daily_graph_backlog=JSON.parse(JSON.stringify(partner_daily));

      ////console.log(serv_temp_partner_moved_C);

      allocation_linechart_s_partner.options.colors=['#DC7633','#E59866','#F6DDCC']
      allocation_linechart_s_partner.dataTable=serv_temp_partner_moved_C;
      this.service_partner_daily_graph_C=JSON.parse(JSON.stringify(allocation_linechart_s_partner));

      allocation_linechart_s_partner.dataTable=serv_temp_partner_rec_C;
      this.service_partner_daily_graph_rec_C=JSON.parse(JSON.stringify(allocation_linechart_s_partner));

      allocation_linechart_s_partner.dataTable=serv_temp_partner_backlog_C;
      this.service_partner_daily_graph_backlog_C=JSON.parse(JSON.stringify(allocation_linechart_s_partner));

      
  }

  getallocationPercBase(partner){
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    // //////////console.log(origin,destination,partner)
    var lane= this.partner_allocation.filter(function(item:any){return item.Partner===partner && item.Origin===origin && item.Destination===destination}) 
    // //////////console.log(lane)
    var flex=0;
    var Baseallocation=0;
    if(lane.length>0)
    {
      if(lane[0].Flex!=null)
      flex=lane[0].Flex;    
    if(lane[0].Baseallocation!=null)
      Baseallocation=lane[0].Baseallocation;

     // //////////console.log(flex,Baseallocation)
    
     return  ((Baseallocation)/(Baseallocation+flex))*100
    }
    
    else {
       return 0;
    }
  }

  getallocationPercflex(partner)
  {
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    // //////////console.log(origin,destination,partner)
    var lane= this.partner_allocation.filter(function(item:any){return item.Partner===partner && item.Origin===origin && item.Destination===destination}) 
    // //////////console.log(lane)
    var flex=0;
    var Baseallocation=0;
    if(lane.length>0)
    {
      if(lane[0].Flex!=null)
      flex=lane[0].Flex;    
    if(lane[0].Baseallocation!=null)
      Baseallocation=lane[0].Baseallocation;

     // //////////console.log(flex,Baseallocation)
    
     return  ((flex)/(Baseallocation+flex))*100
    }
    
    else {
       return 0;
    }
  }
  getallocationBase(partner)
  {
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    var lane= this.partner_allocation.filter(function(item:any){return item.Partner===partner && item.Origin===origin && item.Destination===destination}) 
    if(lane.length>0)
     return lane[0].Baseallocation
    else {
       return 0;
    }
  }
  getallocationBase_network(partner,lane)
  {
    var origin = this.filterselection.selectedlane.split("-")[0]
    var destination = this.filterselection.selectedlane.split("-")[1]
    var lane= this.partner_allocation.filter(function(item:any){return item.Partner===partner && item.Origin===lane.split('-')[0] && item.Destination===lane.split('-')[1]}) 
    if(lane.length>0)
     return lane[0].Baseallocation
    else {
       return 0;
    }
  }
   getpartner_allocation(origin,destination){
    //////console.(theater,region)
    var list = this.partner_allocation.filter(function(item:any){
      //////console.(item.THEATER_NAME,item.REGION_NAME)
      return item.Origin===origin && item.Destination===destination } ) 
 
      return list;
    }

  selecttab(k){

    this.selectedtab=k;
  }
  response_flag=false;
  response_message="";
  validation_flag=true;
  getdata_magic_details(){

     var filter = {
       origin:this.filterselection.selectedlane.split("-")[0],
      destination:this.filterselection.selectedlane.split("-")[1],
      startdate:"",
      endtdate:""
     }
     if(this.filterselection.date.length>0)
      {
      filter.startdate=this.filterselection.date[0].Date
      filter.endtdate=this.filterselection.date[this.filterselection.date.length-1].Date

      }

     if(filter.startdate==""||filter.endtdate==""||filter.destination==""||filter.origin=="")
        {
          this.validation_flag=false;


          this.response_message="Please select "
          if(filter.startdate=="" || filter.endtdate=="")
            this.response_message=this.response_message+ "Week,";
          
          if(filter.origin=="")
            this.response_message=this.response_message+ "origin";
          if(filter.destination=="")
            this.response_message=this.response_message+ " and destination";

            
                alert(this.response_message)
                return
            
              
        }
    //  var filter = {s
    //   origin:'HKG',
    //  destination:'AMS',
    //  startdate:'1/28/2024',
    //  endtdate:'2/3/2024'
    // }

    // this.filterselection.fiscal_week=;
    var fw=this.filterselection.fiscal_week;
    this.filterselection.date = this.fiscal_list.filter(function(item:any){return item.FYQW===fw } ) 


    this.response_message="Please wait..."

    this.response_flag=false;
     this.service.postData(filter, 'get_demand_sensing_magic').then((result) => {

      
      var res:any=result;
      this.data_result.output_json=JSON.parse(res.output_json);
      this.data_result.df_all=JSON.parse(res.df_all);
      this.data_result.df_all_cob=JSON.parse(res.df_all_cob);

            //////////console.log(this.data_result.output_json);
            this.draw_summary_charts();
            this.draw_total_charts();

            this.getdistinctdates();
            this.getdistinctpartner();
            this.getdistinctservicelevel();
            this.getDailybacklog()
            this. generatelistforgrap()
            this.response_flag=true;

     });

     

    // get_demand_sensing_magic


  }

getdata_magic(){

  this.getdata_magic_details();
  return;
 this.response_message="Please wait..."

 var filter = {
  origin:this.filterselection.selectedlane.split("-")[0],
 destination:this.filterselection.selectedlane.split("-")[1],
 startdate:"",
 endtdate:""
}
if(this.filterselection.date.length>0)
 {
 filter.startdate=this.filterselection.date[0].Date
 filter.endtdate=this.filterselection.date[this.filterselection.date.length-1].Date

 }

   if(filter.startdate==""||filter.endtdate=="")
    {
     this.validation_flag=false;


     this.response_message="Please select "
     if(filter.startdate=="" || filter.endtdate=="")
       this.response_message=this.response_message+ "Week,";
     
     if(filter.origin=="")
       this.response_message=this.response_message+ "origin";
     if(filter.destination=="")
       this.response_message=this.response_message+ " and destination";

       alert(this.response_message)
      return;
   }
    this.response_flag=false;
     this.service.postData(filter, 'get_demand_sensing_magic_network').then((result) => {

      //////////console.log("network")
      
      //////////console.log(result)
      // var res:any=result;
      // this.data_result.output_json=JSON.parse(res.output_json);
      // this.data_result.df_all=JSON.parse(res.df_all);
      // this.data_result.df_all_cob=JSON.parse(res.df_all_cob);

      //       //////////console.log(this.data_result.output_json);
      //       this.draw_summary_charts();
      //       this.draw_total_charts();

      //       this.getdistinctdates();
      //       this.getdistinctpartner();
      //       this.getDailybacklog()
      //       this. generatelistforgrap()
            this.response_flag=true;

     });
}

  selectedFilter:any
  filter_saved_list=[]
  onfselect(){
   
    // ////console.(this.selectedFilter)
    // this.filterselection=this.filter_saved_list[this.selectedFilter].filterselection;
    // this.fiscal_list=this.filter_saved_list[this.selectedFilter].list
  }
  save_filters(){

    this.filter_saved_list.push({
      name:this.filterselection.selectedlane.split("-")[0]+'-'+this.filterselection.selectedlane.split("-")[1]+'/'+this.filterselection.fiscal_week,
      list:this.filers,
      filterselection:this.filterselection
    });
    localStorage.setItem('slist',JSON.stringify(this.filter_saved_list))
    alert("Added to your favorite list");
    ////console.(this.filter_saved_list)
  }
  filers ={
    fiscal_year:[],
    fiscalmonth:[],
    fiscal_week:[],
    fiscal_quarter:[],
    fiscal_region_origin:[],
    fiscal_theater_origin:[],
    fiscal_airportcode_origin:[],
    fiscal_region_destination:[],
    fiscal_theater_destination:[],
    fiscal_airportcode_destination:[]
  }
  summaryitems=[];
  onItemSelect(ek){
     ////console.log(this.master_partner_list);

    var index =this.master_partner_list.map(function(e) { return e.Lane; }).indexOf(ek.Lane);

    ////console.log(index);
    var page=index/this.no_element;
   var n = parseInt((page+"").split('.')[0])

    // if(index%this.no_element>0)
    //  n=n+1;
    
     this.summary_page=n;
     ////console.log(this.summary_page)

  }
  onSelectAll(e){}
  get_distinctfiscalyears(){
    ////////console.log(this.fiscal_list);

   var year=this.year;
   var quarter=this.quarter;
   var monht_num=this.monht_num;
    this.filers.fiscal_year = this.fiscal_list
        .map((item) => item[year])
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );

        this.filterselection.fiscal_year=this.filers.fiscal_year[this.filers.fiscal_year.length-1];
        
        this.get_quarter_fiscal_year(this.filterselection.fiscal_year);
        // this.filers.fiscal_quarter =  this.fiscal_list
        // .map((item) => item[quarter])
        // .filter(
        //     (value, index, current_value) => current_value.indexOf(value) === index
        // );

        // //////console.log(quarter)
        // this.filterselection.fiscal_quarter=this.filers.fiscal_quarter[0]
        
        // this.getmonthbyquarter(this.filterselection.fiscal_year,this.filterselection.fiscal_quarter);
  }

  get_distincttheator(){
    this.filers.fiscal_theater_origin = this.lane_region
        .map((item) => item.THEATER_NAME)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
        //////console.(this.filers.fiscal_theater_origin);

  }
  getrigionbhytheater(theater,theaterd)
  {
    //console.log(theater,theaterd)
    if(theater!='')
    {
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theater } ) 
   
      this.filers.fiscal_region_origin = list
          .map((item) => item.REGION_NAME)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
  
    }
    else{
      this.filers.fiscal_region_origin = this.lane_region
          .map((item) => item.REGION_NAME)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    if(theaterd!='')
    {

        var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd } ) 
   
        this.filers.fiscal_region_destination= list
            .map((item) => item.REGION_NAME)
            .filter(
                (value, index, current_value) => current_value.indexOf(value) === index
            );

          }
          else{

            var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd } ) 
   
            this.filers.fiscal_region_destination= this.lane_region
                .map((item) => item.REGION_NAME)
                .filter(
                    (value, index, current_value) => current_value.indexOf(value) === index
                );
          }
            this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane)
         
        // this.getdistinctweek(fiscal_year,fiscal_quarter,this.filers.fiscalmonth[0]);
        this.getairpotbhytheater(this.filterselection.theater_origin,this.filterselection.theater_destination,this.filterselection.region_origin,this.filterselection.region_destination)
  }
  getairpotbhytheater(theater,theaterd,region,regiond){
    // //console.log(theater,theaterd,region,regiond)
  
    

    if(theater!=''&&region!='')
    {
    //  //console.log("1")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.THEATER_NAME===theater && item.REGION_NAME===region } ) 
        // //console.log(list)
      
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );      
    }
    else if(theater!=''&&region==''){
      // //console.log("2")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.THEATER_NAME===theater } ) 
      //////console.(list)
      // //console.log(list)

  
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );    
          // //console.log()
    }

    else if(theater==''&&region!=''){
      // //console.log("3")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.REGION_NAME===region} ) 
      //////console.(list)
  
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );    
    }
    else{
      // //console.log("4")
      this.filers.fiscal_airportcode_origin = this.lane_region
      .map((item) => item['Airport Code'])
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      ); 
    }




    if(theaterd!=''&&regiond!='')
    {
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd && item.REGION_NAME===regiond } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );

    }
    else if(theaterd!=''&&regiond==''){
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd  } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    else if(theaterd==''&&regiond!=''){
      var list = this.lane_region.filter(function(item:any){return  item.REGION_NAME===regiond } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    else{
      this.filers.fiscal_airportcode_destination = this.lane_region
      .map((item) => item['Airport Code'])
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      ); 
    }
        
    // //console.log(this.filers.fiscal_airportcode_origin);
    // //console.log(this.filers.fiscal_airportcode_destination);
            ////console.log(this.filers.fiscal_airportcode_origin )
            ////console.log(this.filers.fiscal_airportcode_destination )
            this.get_data_lane_for_week(this.selectedweekno,this.filterselection.selectedlane)

  }
  get_quarter_fiscal_year(fiscal_year){

    var year=this.year;
    var quarter=this.quarter;
    var monht_num=this.monht_num;
    
    ////console.log(this.fiscal_list)
    ////console.log(fiscal_year,year)
    var list = this.fiscal_list.filter(function(item:any){
      return item[year]==fiscal_year } ) 
    ////console.log(list)

    this.filers.fiscal_quarter = list
    .map((item) => item[quarter])
    .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
    );
    ////console.log(this.filers.fiscal_quarter)
    this.filterselection.fiscal_quarter=this.filers.fiscal_quarter[this.filers.fiscal_quarter.length-1]

    this.getmonthbyquarter(this.filterselection.fiscal_year,this.filterselection.fiscal_quarter);

  }
  getmonthbyquarter(fiscal_year,fiscal_quarter)
  {
    ////console.(this.filterselection)
    var year=this.year;
    var quarter=this.quarter;
    var monht_num=this.monht_num;
    var list = this.fiscal_list.filter(function(item:any){return item[year]==fiscal_year && item[quarter]==fiscal_quarter} ) 
   
    //////console.(this.filers.fiscalmonth)
    this.filers.fiscalmonth = list
        .map((item) => item[monht_num])
        .filter(
            (value, index, current_value) => current_value.indexOf(value) == index
        );
        this.filterselection.fiscal_month= this.filers.fiscalmonth[this.filers.fiscalmonth.length-1]
        // this.getdistinctweek(fiscal_year,fiscal_quarter,this.filers.fiscalmonth[0]);
        this.getdistinctweek(this.filterselection.fiscal_year,this.filterselection.fiscal_quarter,this.filterselection.fiscal_month)
  }
  
  
  getdata(){
    ////console.(this.filterselection)

    this.response_flag=false;
  }
 
  


  
  sortByProperty(property){  
    return function(b,a){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
 }
 sortByPropertyas(property){  
  return function(a,b){  
     if(a[property] > b[property])  
        return 1;  
     else if(a[property] < b[property])  
        return -1;  
 
     return 0;  
  }  
}
  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'Lane',
      textField: 'Lane',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }
  saveData:any=[]

  removeKeyFromObjects = (array, keyToRemove) => {
    return array.map(obj => {
        const { [keyToRemove]: omittedKey, ...rest } = obj;
        return rest;
    });
};
  
gotoallocation(){
  this.router.navigateByUrl('/allocationmonitor');

}

downloadallocation_updated()
{
  let sampleJson: any = [];
  var datad=JSON.parse(this.partner_spend_config_allowlist[this.selectedweekno])
  const keyToRemove = 'PARTNER';

var week_allocation = datad.map(item => {
  // Create a shallow copy of the object
  const { [keyToRemove]: _, ...rest } = item;
  return rest;
});
  //console.log(week_allocation);
  const keysList = Object.keys(week_allocation[0]);
  const data = week_allocation.map(obj => Object.values(obj));

  data.unshift(keysList);
  var workbook: XLSX.WorkBook = XLSX.utils.book_new();
  var worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

  workbook.SheetNames.push("Filtered Data");
  workbook.Sheets["Filtered Data"] = worksheet;

  XLSX.writeFile(workbook,this.filterselection.monthweeklist[this.selectedweekno].replace('-','_')+'_Partner_allocation'+".xlsx");

}

  saveAsCSVshipperdata() {
    let sampleJson: any = [];
    this.shipeerdata.forEach(element => {
      var utcdate=new Date(element.Week).toLocaleString('en-US', { timeZone: 'UTC' });
        var w=utcdate.split(",")[0]  
        element.Week=new Date(w)
        sampleJson.push(element);
    
      
    
    });
   
    sampleJson=this.removeKeyFromObjects(sampleJson, 'index');
    const keysList = Object.keys(sampleJson[0]);

    // Convert array of objects to array of arrays

    const data = sampleJson.map(obj => Object.values(obj));

    data.unshift(keysList);
  //   const data: Array<Array<string>> = [
  //     ["Dhanu", "@gmail.com"],
  //     ["Dhanu1", "@gmail.com"],
  //     ["Dhanu2", "@gmail.com"],
  //     ["Dhanu3", "@gmail.com"],
  //     ["Dhanu4", "@gmail.com"],
  // ];
  
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
  
  workbook.SheetNames.push("Filtered Data");
  workbook.Sheets["Filtered Data"] = worksheet;
  
      XLSX.writeFile(workbook,this.filterselection.fiscal_week+'_Ocean_shipper_data_last13weeks'+".xlsx");
  
    return;
    this.saveData = [];
    let a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let csvData = this.ConvertToCSV(sampleJson);
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.filterselection.fiscal_week+'_Ocean_shipper_data_last13weeks.csv';
    a.click();
  }
  saveAsCSV() {

    // var filter ={'weeklist':this.filterselection.monthweeklist,filename:'partner_data'}

    // this.service.download_data('partner_file.xlsx',filter)
    
    // const arrayOfObjects = [
    //   { name: 'John', age: 30, city: 'New York' },
    //   { name: 'Alice', age: 25, city: 'Los Angeles' },
    //   { name: 'Bob', age: 35, city: 'Chicago' }
    // ];
    
    const keysList = Object.keys(this.weekdownloadlist[0]);

    var k=0;
    this.weekdownloadlist.forEach(element => {
      
      
        if(this.weekdownloadlist[k].FREIGHT_RCVD_DATE!='' &&  this.weekdownloadlist[k].FREIGHT_RCVD_DATE!=null)
             this.weekdownloadlist[k].FREIGHT_RCVD_DATE=new Date(element.FREIGHT_RCVD_DATE)
            
        if(this.weekdownloadlist[k].FREIGHT_BOOKED!='' &&  this.weekdownloadlist[k].FREIGHT_BOOKED!=null)
            this.weekdownloadlist[k].FREIGHT_BOOKED=new Date(element.FREIGHT_BOOKED)

        if(this.weekdownloadlist[k].COB_DATE!='' &&  this.weekdownloadlist[k].COB_DATE!=null)
            this.weekdownloadlist[k].COB_DATE=new Date(element.COB_DATE)

        k=k+1;

    });
    // Convert array of objects to array of arrays

    const data = this.weekdownloadlist.map(obj => Object.values(obj));

    data.unshift(keysList);
  //   const data: Array<Array<string>> = [
  //     ["Dhanu", "@gmail.com"],
  //     ["Dhanu1", "@gmail.com"],
  //     ["Dhanu2", "@gmail.com"],
  //     ["Dhanu3", "@gmail.com"],
  //     ["Dhanu4", "@gmail.com"],
  // ];
  
  var workbook: XLSX.WorkBook = XLSX.utils.book_new();
  var worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
  
//   var dateFormat = "MM/DD/YYYY";
//   var numberFormat = "#,##0.00";
//   // worksheet['F'].z = numberFormat; // Applying number format to Age column (Column B)
//   // worksheet['C2'].z = dateFormat;
//   for (let row = 1; row < data.length; row++) { // Start from row 1 (skip header row)
//     var  cellAddress = `F${row + 1}`;
//   //console.log(cellAddress)
//     if(worksheet[cellAddress].z)
//      worksheet[cellAddress].z = numberFormat;

//     cellAddress = `I${row + 1}`;
//     if(worksheet[cellAddress].z)
//      worksheet[cellAddress].z = numberFormat;
   
//     // var  cellAddress = `G${row + 1}`;
//     // worksheet[cellAddress].z = dateFormat;
//     // cellAddress = `H${row + 1}`;
//     // worksheet[cellAddress].z = dateFormat;
//     // cellAddress = `J${row + 1}`;
//     // worksheet[cellAddress].z = dateFormat;

    
//  }
    workbook.SheetNames.push("Filtered Data");
    workbook.Sheets["Filtered Data"] = worksheet;
  
    const data1: Array<Array<string>> =[["PARTNER","Name of the Partner, use this column to filter on a specific partner"],
    ["SHIPPER_NAME ","Name of the shipper or supplier"],
    ["ORIGIN_BRANCH ","Origin airport, use this column to filter on a specific lane"],
    ["DESTIN_BRANCH ","Destination airport, use this column to filter on a specific lane"],
    ["HAWB ","House Airway Bill number"],
    ["FREIGHT_RCVD_WEIGHT_ACTUAL ","Please ignore, it is actual weight in Kgs"],
    ["FREIGHT_RCVD_DATE ","This is important key milestone date used in the calculations, this is the date when partner receives the freight at their location"],
    ["FREIGHT_BOOKED ","Please ignore, this is another milestone but is not used in the calculations."],
    ["COB_WEIGHT_CHARGEABLE ","This is volumetric weight used in all the calculations like received, backlog or moved volume."],
    ["COB_DATE ","This is important key milestone date stands for Confirmed on Board, and is used in the calculations. This is the date when the freight onboards the aircraft."],
    ["Service Level ","Service level (standard, deferred, expedite, dangerous goods) "],
    ["PW RCV ","Total of this column will provide the total volume which was received in the week prior to the selected week, but it was not moved within the week it was received."],
    ["PW RCV and TW COB ","Total of this column will provide the total volume which was received in the week prior to the selected week, and it was moved the following week i.e. during the selected week."],
    ["TW RCV ","Total of this column will provide the total volume which is received within the selected week, which is not yet moved."],
    ["TW RCV and TW COB ","Total of this column will provide the total volume which is received in the selected week and is also moved within that week. This will only limit to the volume received in the selected week and will exclude any volume which was received in the week prior to the selected week."], 
    ["TW COB ","Total of this column will provide the total volume moved within the selected week. It can also include volume from prior week (PW RCV)."],
    ["Backlog Moved ","Total of this column will provide prior week's backlog which was moved within the selected week: PW RCV and TW COB"],
    ["Received ","Total of this column will provide total volume received during the selected week :TW RCV"],
    ["Received Moved ","Total of this column will provide total volume which was both received and moved within the selected week: TW RCV and TW COB"],
    ["Total Moved ","Total of this column will provide total of Backlog Moved and Received Moved i.e. Total volume moved during the selected week which can be either backlog from prior weeks or was received during the selected week: PW RCV and TW COB + TW RCV and TW COB"],
    ["Total Received ","This is total of Received and Received Moved: TW RCV + TW RCV and TW COB"],
    ["Total Backlog ","This is total backlog: (PW RCV – PW RCV and TW COB) + TW RCV"]];

      const worksheet1: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data1);
  
      workbook.SheetNames.push("Description of all the columns");
      workbook.Sheets["Description of all the columns"] = worksheet1;

      XLSX.writeFile(workbook,this.filterselection.monthweeklist[this.selectedweekno].replace('-','_')+'_Partner_Data'+".xlsx");
  
    return;
    let sampleJson: any = [];
    this.download_Data.forEach(element => {
        sampleJson.push(element);
    
      
    
    });
   
    this.saveData = [];
    let a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let csvData = this.ConvertToCSV(sampleJson);
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.filterselection.fiscal_year+'_M'+this.filterselection.fiscal_month+'_Partner_Data.csv';
    a.click();
  }
 
  saveAsCSVsum() {
    let sampleJson: any = [];
    // .forEach(element1=> {
      var week_data=JSON.parse(this.master_list[this.selectedweekno].data)
      var week_datad:any=[]
      var ctx=this;
        //console.log(week_data)
        if(this.filers.fiscal_airportcode_origin.length>0&&this.filers.fiscal_airportcode_destination.length==0)
          {
            week_datad=week_data.filter(function(item:any){
              return ctx.check_origin_exitst(item.Lane.split('-')[0])>-1});     
              //console.log("1")     
          }
          else if(this.filers.fiscal_airportcode_origin.length==0&&this.filers.fiscal_airportcode_destination.length>0)
          {
            week_datad=week_data.filter(function(item:any){
              return ctx.check_destination_exitst(item.Lane.split('-')[1])>-1});          
              //console.log("2")     
    
          }
          else if(this.filers.fiscal_airportcode_destination.length>0&&this.filers.fiscal_airportcode_origin.length>0)
          {
            week_datad=week_data.filter(function(item:any){
            return ctx.check_origin_exitst(item.Lane.split('-')[0])>-1 && ctx.check_destination_exitst(item.Lane.split('-')[1])>-1});
            //console.log("3")     
    
          }
          else{
            week_datad=week_data;
            //console.log("4")     
    
          }

          week_datad.forEach(element => {
        // element['Week']=new Date(this.master_list[this.selectedweekno].week.split('-')[0]);

        sampleJson.push(element);
        
    
      // });
    
    });
   
    const keysList = ["Lane","PARTNER","Previous Weeks Received","Backlog Moved","This Week Received","This Week Moved","Booked but not Received","Total Moved","Backlog Moved","Backlog Received","Total Backlog","Total Received","Total Volume","Baseallocation","Flex","MinAllocaion"];

    // Convert array of objects to array of arrays

    const data = sampleJson.map(obj => Object.values(obj));

    data.unshift(keysList);
  //   const data: Array<Array<string>> = [
  //     ["Dhanu", "@gmail.com"],
  //     ["Dhanu1", "@gmail.com"],
  //     ["Dhanu2", "@gmail.com"],
  //     ["Dhanu3", "@gmail.com"],
  //     ["Dhanu4", "@gmail.com"],
  // ];
  
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();

  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
  
  workbook.SheetNames.push("First");
  workbook.Sheets["First"] = worksheet;
  
      // XLSX.writeFile(workbook, this.filterselection.monthweeklist[this.selectedweekno].replace('-','_')+'Summary'+".xlsx");


      
          XLSX.writeFile(workbook, this.filterselection.monthweeklist[this.selectedweekno].replace('-','_')+'Summary'+".xlsx");
    
      return;
    ////console.log(sampleJson)
    this.saveData = [];
    let a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let csvData = this.ConvertToCSVsum(sampleJson);
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = this.filterselection.fiscal_year+'_M'+this.filterselection.fiscal_month+'Summary.csv';
    a.click();
  }
  
 
  
  ConvertToCSVsum(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    ////console.log(array)
    let str = '';
    let row = "";
    for (let index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    row="Lane,PARTNER,Previous Weeks Received,Backlog Moved,This Week Received,This Week Moved,Total Moved,Backlog Moved,Backlog Received,Total Backlog,Total Received,Total Volume,Baseallocation,Flex,MinAllocaion,Week"
    // row="Lane,PARTNER,Privious,pw_received_twcob,tw_received,tw_received_twcob,tw_moved,backlog_moved,backlog_received,total_backlog,total_received,total_volume,Baseallocation,Flex,MinAllocaion"

    ////console.log(row)
    //append Label row with line break
    str += row + '\r\n';
  
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  ConvertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    ////console.log(array)
    let str = '';
    let row = "";
    for (let index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    // row="Lane,PARTNER,Previous Weeks Received,Backlog Moved,This Week Received,This Week Moved,Total Moved,Backlog Moved,Backlog Received,Total Backlog,Total Received,Total Volume"
    // row="Lane,PARTNER,Privious,pw_received_twcob,tw_received,tw_received_twcob,tw_moved,backlog_moved,backlog_received,total_backlog,total_received,total_volume,Baseallocation,Flex,MinAllocaion"

    ////console.log(row)
    //append Label row with line break
    str += row + '\r\n';
  
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
  GOtoNExtPage_2() {
    
    this.router.navigateByUrl('/operational-concern');
  }
  gototraining(){
    this.router.navigateByUrl('/dailytraining');

  }
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operationasl-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }
  go_config1()
  {
    this.router.navigateByUrl('/userprofile');
  
  }
}
